import { IdentifyModel } from "./models";
import { Task } from "~/services/taskDeprecated/Task";
import { TaskQueue } from "~/services/taskDeprecated/TaskQueue";
import { getTikTokPixelCookie, getTikTokPixelUrlQuery } from "~/services/tiktok/helpers";

class TikTok extends TaskQueue {
  public static TIKTOK_KEYWORD: string = "tiktok";
  public static TTL: number = 10000;

  public get ttq(): any {
    return window.ttq;
  }

  public get isEnabled(): boolean {
    return this.ttq && this.ttq?._autoam?.initialize;
  }

  public get ttclid(): string {
    return getTikTokPixelUrlQuery() || getTikTokPixelCookie();
  }

  public alias(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.alias(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public debug(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.debug(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public disableCookie(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.disableCookie(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public enableCookie(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.enableCookie(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public group(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.group(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public identify(identify: IdentifyModel): void {
    const task: Task = new Task((identify: IdentifyModel) => {
      this.ttq.identify(identify);
    })
      .setTtl(TikTok.TTL)
      .setArguments([identify])
      .setContext(TikTokService);

    this.execute(task);
  }

  public instances(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.instances(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public off(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.off(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public on(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.on(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public once(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.once(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public page(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.page(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public ready(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.ready(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  public track(...args: any[]): void {
    const task: Task = new Task((...args: any[]) => {
      this.ttq.track(...args);
    })
      .setTtl(TikTok.TTL)
      .setArguments(args)
      .setContext(TikTokService);

    this.execute(task);
  }

  private execute(task: Task): void {
    task && this.addTask(task);

    if (this.isEnabled) {
      this.executeTasks();
    }
  }
}

const TikTokService: TikTok = new TikTok();

export { TikTokService, TikTok };
