import { trackBrandzooka } from "./trackBrandzooka";
import { isScriptLoaded, loadScript } from "~/utils";

import { LoggerService } from "~/services/logger";

declare global {
  interface Window {
    ttd_dom_ready: (arg: () => void) => void;
    TTDUniversalPixelApi: {
      new (): any;
    };
  }
}

const initPixel = (): void => {
  if (typeof window?.ttd_dom_ready !== "function") {
    LoggerService.error("Brandzooka script failed to load.");
    return;
  }

  window.ttd_dom_ready(function () {
    trackBrandzooka();
  });
};

const loadBrandzooka = (callback: Function | void = null): void => {
  try {
    const scriptUrl: string = `https://js.adsrvr.org/up_loader.1.1.0.js`;
    if (isScriptLoaded(scriptUrl)) {
      LoggerService.warn("Brandzooka script is already loaded.", scriptUrl);
      initPixel();
      return;
    }

    loadScript(scriptUrl, () => {
      initPixel();
      callback && callback();
    });
  } catch (e) {
    LoggerService.error("Brandzooka script error: ", e);
  }
};

export { loadBrandzooka };
