class PageModel {
  public path: string = "";
  public hash: string = "";
  public query: Record<string, string | string[]> = {};

  public constructor(page: Partial<PageModel> = {}) {
    Object.assign(this, page);
  }
}

export { PageModel };
