import parseAsString from "lodash/toString";

import { LoggerService } from "~/services/logger";

const stringifyValue = (value: any = null): string => {
  try {
    return parseAsString(JSON.stringify(value));
  } catch (e) {
    LoggerService.error("stringifyValue", e);
  }

  return "";
};

export { stringifyValue };
