class Logger {
  private _debug: boolean = false;

  public get isDebugEnabled(): boolean {
    return this._debug;
  }

  public set isDebugEnabled(debug: boolean) {
    this._debug = debug;
  }

  public log(...args: any[]): void {
    if (this.isDebugEnabled) {
      console.log(...args);
    }
  }

  public info(...args: any[]): void {
    if (this.isDebugEnabled) {
      console.info(...args);
    }
  }

  public warn(...args: any[]): void {
    if (this.isDebugEnabled) {
      console.warn(...args);
    }
  }

  public error(...args: any[]): void {
    if (this.isDebugEnabled) {
      console.error(...args);
    }
  }
}

export const LoggerService: Logger = new Logger();
