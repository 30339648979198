import { LoggerService } from "~/services/logger";
import { isScriptLoaded, loadScript } from "~/utils";

declare global {
  interface Window {
    rdt?: any;
  }
}

const scriptLoader = (pixelId: string, callback: () => void = (): void => {}): void => {
  let rdt = window?.rdt;

  if (!rdt) {
    rdt = window.rdt = function () {
      rdt.sendEvent ? rdt.sendEvent.apply(rdt, arguments) : rdt.callQueue.push(arguments);
    };
    rdt.callQueue = [];

    const redditPixelUrl: string = "https://www.redditstatic.com/ads/pixel.js";

    if (isScriptLoaded(redditPixelUrl)) {
      LoggerService.warn("Reddit Pixel script is already loaded.", redditPixelUrl);
      callback();
      return;
    }

    loadScript(redditPixelUrl, callback);
  }
};

const loadRedditPixel = (pixelId: string, callback?: () => void): void => {
  if (!pixelId?.length) {
    LoggerService.error("Reddit pixel id is missing.", pixelId);
    return;
  }

  scriptLoader(pixelId, callback);
};

export { loadRedditPixel };
