import { VisitModel } from "../models";

import { isUtmModelEmpty } from "./isUtmModelEmpty";
import { filterUniqueModels } from "./filterUniqueModels";

const filterUniqueModelsWithUtm = (models: VisitModel[] = []): VisitModel[] => {
  if (!models?.length) {
    return [];
  }

  return filterUniqueModels(models.filter((visit: VisitModel) => !isUtmModelEmpty(visit?.data)));
};

export { filterUniqueModelsWithUtm };
