import type { EventPayload } from "../../../types";
import type {
  AddShippingInfoEvent,
  BeginCheckoutEvent,
  GenerateLeadEvent,
  GoogleTagEvent,
  GoogleTagItem,
  PurchaseEvent,
  QualifyLeadEvent,
  SignUpEvent,
  ViewPromotionEvent,
  WorkingLeadEvent,
} from "../types";

import { GoogleTagEventName } from "../types/googleTagEventName";

import { AnalyticsEventNames } from "~/mixins/demographics/types/analyticsEventNames";

const getQuizProduct = (eventPayload?: EventPayload): GoogleTagItem[] => {
  const item: GoogleTagItem = {
    discount: 1.0,
    affiliation: "Curex Store",
    index: 0,
    item_brand: "Curex",
    item_id: eventPayload?.properties?.version || "checkout",
    item_name: "Quiz",
    item_variant: "quiz",
    price: 49.0,
    quantity: 1,
  };

  if (eventPayload?.context?.campaign?.coupon?.length) {
    item.coupon = eventPayload?.context?.campaign?.coupon;
  }

  return [item];
};

const beginCheckoutEventProperties = (eventPayload?: EventPayload): BeginCheckoutEvent["properties"] => {
  const properties: BeginCheckoutEvent["properties"] = {
    currency: "USD",
    value: 10.0,
    items: getQuizProduct(eventPayload),
  };

  if (eventPayload?.context?.campaign?.coupon?.length) {
    properties.coupon = eventPayload?.context?.campaign?.coupon;
  }

  return properties;
};

const generateLeadEventProperties = (eventPayload?: EventPayload): GenerateLeadEvent["properties"] => ({
  currency: "USD",
  value: 20.0,
  lead_source: "quiz",
  items: getQuizProduct(eventPayload),
});

const authorizationEventProperties = (eventPayload?: EventPayload): SignUpEvent["properties"] => ({
  method: eventPayload?.properties?.method || "passwordless",
});

const qualifyLeadEventProperties = (eventPayload?: EventPayload): QualifyLeadEvent["properties"] => ({
  currency: "USD",
  value: 30.0,
  items: getQuizProduct(eventPayload),
});

const viewPromotionEventProperties = (eventPayload?: EventPayload): ViewPromotionEvent["properties"] => ({
  creative_name: "quiz_promotion",
  creative_slot: "quiz_promotion",
  promotion_id: "1",
  promotion_name: "Quiz discount",
  items: getQuizProduct(eventPayload),
});

const purchaseEventProperties = (eventPayload?: EventPayload): PurchaseEvent["properties"] => {
  const properties: PurchaseEvent["properties"] = {
    currency: "USD",
    value: 49.0,
    transaction_id: eventPayload?.properties?.sessionId,
    shipping: 1.0,
    tax: 1.0,
    items: getQuizProduct(eventPayload),
  };

  if (eventPayload?.context?.campaign?.coupon?.length) {
    properties.coupon = eventPayload?.context?.campaign?.coupon;
  }

  return properties;
};

const addShippingInfoEventProperties = (eventPayload?: EventPayload): AddShippingInfoEvent["properties"] => {
  const properties: AddShippingInfoEvent["properties"] = {
    currency: "USD",
    value: 60.0,
    items: getQuizProduct(eventPayload),
  };

  if (eventPayload?.context?.campaign?.coupon?.length) {
    properties.coupon = eventPayload?.context?.campaign?.coupon;
  }

  return properties;
};

const workingLeadEventProperties = (eventPayload?: EventPayload): WorkingLeadEvent["properties"] => ({
  currency: "USD",
  value: 40.0,
  lead_status: "Started conversations",
  items: getQuizProduct(eventPayload),
});

const eventPayloadToGoogleTagEvents = (event: string, eventPayload?: EventPayload): GoogleTagEvent[] => {
  const result: GoogleTagEvent[] = [
    {
      name: event,
      properties: eventPayload?.properties || {},
    },
  ];

  switch (event) {
    case AnalyticsEventNames.CHECKOUT_SESSION_CREATED: {
      result.push({
        name: GoogleTagEventName.BEGIN_CHECKOUT,
        properties: beginCheckoutEventProperties(eventPayload),
      });
      break;
    }

    case AnalyticsEventNames.CHECKOUT_USER_SIGNED_UP: {
      result.push({
        name: GoogleTagEventName.GENERATE_LEAD,
        properties: generateLeadEventProperties(eventPayload),
      });
      break;
    }

    case AnalyticsEventNames.USER_SIGNED_UP: {
      result.push({
        name: GoogleTagEventName.SIGN_UP,
        properties: authorizationEventProperties(eventPayload),
      });
      break;
    }

    case AnalyticsEventNames.USER_SIGNED_IN: {
      result.push({
        name: GoogleTagEventName.LOGIN,
        properties: authorizationEventProperties(eventPayload),
      });
      break;
    }

    case "Question Viewed": {
      if (!eventPayload?.properties?.key?.length || !eventPayload?.properties?.version?.length) {
        break;
      }

      if (eventPayload?.properties.version?.includes("checkout")) {
        switch (eventPayload?.properties?.key) {
          // redirected to stripe form
          case "uhajqzgpjv": {
            result.push({
              name: GoogleTagEventName.QUALIFY_LEAD,
              properties: qualifyLeadEventProperties(eventPayload),
            });
            break;
          }
          // limited offer
          case "pqokjrjhbc": {
            result.push({
              name: GoogleTagEventName.VIEW_PROMOTION,
              properties: viewPromotionEventProperties(eventPayload),
            });
            break;
          }
        }
        break;
      }

      if (eventPayload?.properties.version?.includes("medical-history")) {
        switch (eventPayload?.properties?.key) {
          // for whom
          case "idupbsgyus": {
            result.push({
              name: GoogleTagEventName.ADD_SHIPPING_INFO,
              properties: addShippingInfoEventProperties(),
            });
          }
        }
        break;
      }

      break;
    }

    case AnalyticsEventNames.CHECKOUT_SESSION_COMPLETED: {
      result.push({
        name: GoogleTagEventName.PURCHASE,
        properties: purchaseEventProperties(eventPayload),
      });
      break;
    }

    case "Office Hours - Join Now":
    case "Office Hours - Schedule": {
      result.push({
        name: GoogleTagEventName.WORKING_LEAD,
        properties: workingLeadEventProperties(eventPayload),
      });
    }
  }

  return result;
};

export { eventPayloadToGoogleTagEvents };
