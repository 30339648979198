import { Builder } from "builder-pattern";

import { TemplateUrlOptions } from "../types";
import { templateOptionsDefault } from "./templateOptionsDefault";

const templateUrlOptionsDefault: TemplateUrlOptions = Builder<TemplateUrlOptions>(templateOptionsDefault)
  .normalize(true)
  .build();

export { templateUrlOptionsDefault };
