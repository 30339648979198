import postHog from "posthog-js";
import isNil from "lodash/isNil";

import { Destination } from "../../abstracts/Destination";

import type { EventPayload } from "../../types";

import { eventPayloadToPosthogCapturePropertiesData } from "./helpers";

import { QueryParameter } from "~/services/QueryStorage/types";

import { cleanObject } from "~/utils/filters/cleanObject";

class PostHogDestination extends Destination {
  private _postHogInstance: typeof postHog = postHog;

  public constructor(payload?: EventPayload) {
    super({ id: process?.env?.CX_POSTHOG_ID ?? "7w0IT0E09cUPJsPPNfATVMOMOfyc5c4KLGUQHYVY8po", name: "PostHog" });

    this.loadIntegration(payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return !!this.id.length && !isNil(this._postHogInstance) && this._postHogInstance?.__loaded;
  }

  public loadIntegration(payload?: EventPayload): void {
    try {
      // https://posthog.com/docs/libraries/js#config
      this._postHogInstance.init(this.id, {
        api_host: "https://us.i.posthog.com",
        capture_pageview: false,
        autocapture: false,
        disable_surveys: true,
        mask_all_text: true, // disable capturing of user entered text
        custom_campaign_params: Object.values(QueryParameter),
        bootstrap: cleanObject({
          deviceID: payload?.anonymousId,
          distinctID: payload?.userId,
        }),
      });

      this.initDestination();
    } catch (error: unknown) {
      this.logError("Failed to load", this.id, error);
    }
  }

  public alias(userId: string, previousId?: string): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to alias", this.id);
        return;
      }

      this._postHogInstance.alias(userId, previousId);
    });
  }

  public identify(payload: EventPayload): void {
    if (!payload?.userId?.length) {
      this.logError("Cannot identify without userId property to identify", this.id);
      return;
    }

    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to identify", this.id);
        return;
      }

      this._postHogInstance.identify(payload?.userId, payload?.traits || {});
    });
  }

  public page(payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track page", this.id);
        return;
      }

      this._postHogInstance.capture("$pageview", eventPayloadToPosthogCapturePropertiesData(payload));
    });
  }

  public track(event: string, payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track", this.id);
        return;
      }

      this._postHogInstance.capture(event, eventPayloadToPosthogCapturePropertiesData(payload));
    });
  }
}

export { PostHogDestination };
