import type { EventPayload } from "../../../types";

import type { GoogleTagCampaignData } from "../types";

const eventPayloadToGoogleTagCampaignData = (eventPayload?: EventPayload): GoogleTagCampaignData => {
  return {
    id: eventPayload?.context?.campaign?.id || undefined,
    source: eventPayload?.context?.campaign?.source || undefined,
    medium: eventPayload?.context?.campaign?.medium || undefined,
    name: eventPayload?.context?.campaign?.name || undefined,
    term: eventPayload?.context?.campaign?.term || undefined,
    content: eventPayload?.context?.campaign?.content || undefined,
  };
};

export { eventPayloadToGoogleTagCampaignData };
