import { StorageService } from "~/services/storage/Storage";

type CustomNode = {
  tag: string;
  props: Record<string, any>;
  children: (CustomNode | string)[];
};

const h = (tag: string, props: Record<string, any> = {}, ...children: (CustomNode | string)[]): CustomNode => ({
  tag,
  props,
  children,
});

const setStyles = (element: HTMLElement, styles: Record<string, string>): void => {
  Object.assign(element.style, styles);
};

const createElement = (customNode: CustomNode | string): HTMLElement => {
  if (typeof customNode === "string") {
    return document.createTextNode(customNode) as unknown as HTMLElement;
  }

  const element = document.createElement(customNode.tag);

  Object.entries(customNode.props).forEach(([key, value]) => {
    if (key.startsWith("on")) {
      element.addEventListener(key.substring(2).toLowerCase(), value);
    } else if (key === "style") {
      setStyles(element, value as Record<string, string>);
    } else {
      element.setAttribute(key, value);
    }
  });

  customNode.children.forEach((child) => {
    element.appendChild(createElement(child));
  });

  return element;
};

const PromoBanner = (): CustomNode => {
  const resetMarginAndPadding = {
    margin: "0",
    padding: "0",
  };

  const linkStyles = {
    ...resetMarginAndPadding,
    color: "#0573BE",
    cursor: "pointer",
    textDecoration: "none",
  };

  const commonTextStyles = {
    color: "#000",
    margin: "0",
    fontSize: "1rem",
    fontFamily: `"TWK Lausanne", sans-serif`,
    lineHeight: "1.6",
  };

  const commonButtonStyles = {
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    cursor: "pointer",
    display: "inline-block",
    position: "relative",
    textDecoration: "none",
    fontFamily: `"TWK Lausanne", sans-serif`,
    textTransform: "none",
    userSelect: "none",
    margin: "0",
    textAlign: "center",
    borderRadius: "600rem",
    padding: "0.5rem",
    flexBasis: "8rem",
    flexGrow: "1",
    maxWidth: "10rem",
  };

  return h(
    "div",
    {
      style: {
        position: "fixed",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: "9999999999999999",
        padding: "1rem",
        boxShadow: "0 -0.3125rem 1.25rem rgba(0,0,0,0.05)",
        opacity: "0",
        transition: "transform 0.2s ease, opacity 0.3s ease",
        transform: "translateY(100%)",
        willChange: "transform",
        backdropFilter: "blur(0.625rem)",
        webkitBackdropFilter: "blur(0.625rem)",
        backgroundColor: "rgba(255, 180, 210, 0.7)",
      },
    },
    h(
      "p",
      {
        class: "cx-display-block-mobile",
        style: {
          ...resetMarginAndPadding,
          display: "flex",
          gap: "0.5rem",
          margin: "auto",
          maxWidth: "58.75rem",
        },
      },
      h(
        "span",
        {
          class: "cx-text-align-center-mobile",
          style: {
            ...commonTextStyles,
            display: "block",
            textAlign: "left",
            maxWidth: "43em",
            margin: "auto",
          },
        },
        h(
          "span",
          { style: { margin: "0", textWrap: "balance" } },
          `We use cookies and similar technologies on our websites to enhance and tailor your experience, analyze our traffic, and for security and marketing. You can choose not to allow tracking cookies by clicking "Deny all". For more information see our `,
          h(
            "a",
            {
              target: "_blank",
              href: "/legal/privacy-policy",
              style: linkStyles,
            },
            "Cookie Policy",
          ),
          ".",
        ),
      ),

      h(
        "span",
        {
          style: {
            ...commonTextStyles,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.75rem",
            marginTop: "0.5rem",
            marginRight: "auto",
            minWidth: "16rem",
          },
        },
        h(
          "button",
          {
            type: "button",
            style: {
              ...commonTextStyles,
              ...commonButtonStyles,
              backgroundColor: "transparent",
              border: "1px solid black",
            },
            onclick: () => {
              StorageService.set("cookie-consented", "no");
              document?.getElementById("cookie-consent-banner")?.remove();
            },
          },
          "Deny all",
        ),
        h(
          "button",
          {
            type: "button",
            style: {
              ...commonTextStyles,
              ...commonButtonStyles,
              backgroundColor: "#0DCE52",
              border: "1px solid #0DCE52",
            },
            onclick: () => {
              StorageService.set("cookie-consented", "yes");
              document?.getElementById("cookie-consent-banner")?.remove();
            },
          },
          "Accept all",
        ),
      ),
    ),
  );
};

const addCookieConsentBanner = (): void => {
  try {
    if (StorageService.has("cookie-consented")) {
      return;
    }

    const mainWrapper = document?.body as HTMLElement;
    if (!mainWrapper) {
      console.error("Failed to create cookie consent banner: Body not found not found.");
      return;
    }

    const insertStyles = () => {
      const element = document.createElement("style");

      let stylesOne = " @media (max-width: 768px) {";
      stylesOne += " .cx-display-block-mobile {";
      stylesOne += "display: block !important;";
      stylesOne += "}";

      let stylesTwo = " @media (max-width: 768px) {";
      stylesTwo += " .cx-text-align-center-mobile {";
      stylesTwo += "text-align: center !important;";
      stylesTwo += "}";

      document.head.appendChild(element);
      element.sheet.insertRule(stylesOne, 0);
      element.sheet.insertRule(stylesTwo, 1);
    };

    const renderBanner = (): void => {
      const existingBanner = document.getElementById("cookie-consent-banner");
      if (existingBanner) {
        existingBanner.remove();
      }

      const promoBanner = createElement(PromoBanner());
      promoBanner.id = "cookie-consent-banner";
      mainWrapper.append(promoBanner);

      setTimeout(() => {
        if (promoBanner) {
          promoBanner.style.opacity = "1";
          promoBanner.style.transform = "translateY(0)";
        }
      }, 100);
    };

    insertStyles();
    renderBanner();

    /*     setTimeout(() => {
          if (StorageService.has("cookie-consented")) {
            return;
          }

          StorageService.set("cookie-consented", "undecided");
        }, 60000); */
  } catch (e) {
    console.error("Cookie consent banner error:", e);
  }
};

export { addCookieConsentBanner };
