// It seems that actually init is a track function that should be called on every page in our spa
const pageViewBrandzooka = (): void => {
  if (!window?.fetch) {
    return;
  }

  fetch("https://insight.adsrvr.org/track/pxl/?adv=2nkznre&ct=0:5jvx31w&fmt=3", {
    method: "GET",
    credentials: "include",
    mode: "no-cors",
    keepalive: true,
  });
};

export { pageViewBrandzooka };
