import Cookies, { CookieAttributes } from "js-cookie";

import { removeQuotes } from "~/utils/converters/removeQuotes";

/** Execute function once per some period of time. */
const executeOncePerPeriod = async <T extends Function>(
  cookieName: string,
  callback: T,
  cookieAttributes: CookieAttributes = { expires: 1 },
  cookieValue: string = "yes",
): Promise<void> => {
  const cookie: string = removeQuotes(Cookies.get(cookieName));
  if (cookie.length && cookie === cookieValue) {
    // Cookie is present, dont execute
    return;
  }

  Cookies.set(cookieName, cookieValue, cookieAttributes);

  await callback();
};

export { executeOncePerPeriod };
