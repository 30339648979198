import { ErrorInfoModel, ErrorModel, InterceptorModel } from "../../models";
import { ErrorType, InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";
import { errorResponseError } from "../../errors";
import { isJsonRequest, parseErrorFromBackend, parseResponseAsJson, parseResponseAsString } from "../../helpers";

import { isJsonString } from "~/utils";

const errorResponse: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.ERROR,
  type: InterceptorType.RESPONSE,
  callback: async <T>({ request, response }: InterceptorPayload<T>): Promise<InterceptorPayload<T>> => {
    if (response.ok) {
      return { request, response };
    }

    const requestData: object = isJsonString(request?.body) ? JSON.parse(request.body) : {};
    if (isJsonRequest(request)) {
      throw errorResponseError(parseErrorFromBackend(await parseResponseAsJson(response)), requestData);
    }

    const dataFromResponse: ErrorInfoModel = new ErrorInfoModel({
      code: response?.status,
      detail: response?.statusText,
    });

    const responseText: string = (await parseResponseAsString(response)).substring(0, 100);
    if (responseText.length) {
      dataFromResponse.message.push(responseText);
    }

    throw errorResponseError(new ErrorModel({ type: ErrorType.BACKEND, data: dataFromResponse }), requestData);
  },
});

export { errorResponse };
