import { Task } from "./Task";

/** Class representing a queue of Tasks. */
class TaskQueue {
  protected static _DEBUG: boolean = false;

  protected _isExecuting: boolean = false;
  protected _autoExecute: boolean = false;
  protected _queue: Task[] = [];

  /**
   * @return {boolean} Returns if any task execution is in progress.
   */
  public get isExecuting(): boolean {
    return this._isExecuting;
  }

  /**
   * @param {boolean=false} autoExecute - Auto execute tasks when added.
   * @return {TaskQueue} TaskQueue instance.
   */
  public autoExecuteTasks(autoExecute: boolean = false): TaskQueue {
    this._autoExecute = autoExecute;
    return this;
  }

  /**
   * @return {Promise<TaskQueue>} TaskQueue instance.
   */
  public async executeTasks(): Promise<TaskQueue> {
    if (this._isExecuting || !this._queue.length) {
      return;
    }

    this._isExecuting = true;

    const nextTask: Task = this._queue.shift();
    TaskQueue._DEBUG && console.time(`Task #${nextTask.fingerprint}# execution took`);
    await nextTask.execute();
    TaskQueue._DEBUG && console.timeEnd(`Task #${nextTask.fingerprint}# execution took`);

    this._isExecuting = false;

    await this.executeTasks();
    return this;
  }

  /**
   * Empty task queue. No Tasks will be executed.
   * @return {TaskQueue} TaskQueue instance.
   */
  public clearTasks(): TaskQueue {
    this._queue = [];
    return this;
  }

  /**
   * Add task to queue. Task will be executed immediately if autoExecuteTasks is true.
   * @param {Task} task - Task instance.
   * @return {TaskQueue} TaskQueue instance.
   */
  public addTask(task: Task): TaskQueue {
    this._queue.push(task);
    return this;
  }

  /**
   * Prepend adds task to the beginning of the queue. Task will be executed immediately if autoExecuteTasks is true.
   * @param {Task} task - Task instance.
   * @return {TaskQueue} TaskQueue instance.
   */
  public prependTask(task: Task): TaskQueue {
    this._queue = [task, ...this._queue];
    return this;
  }
}

export { TaskQueue };
