import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import pickBy from "lodash/pickBy";

class UtmModel {
  public coupon?: string;

  // click id
  public gclid?: string;
  public gbraid?: string;
  public wbraid?: string;
  public gclsrc?: string;
  public dclid?: string;
  public gad_source?: string;
  public twclid?: string;
  public msclkid?: string;
  public igshid?: string;
  public fbclid?: string;
  public rdt_cid?: string;
  public li_fat_id?: string;
  public irclickid?: string;
  public sccid?: string;
  public ttclid?: string;

  // utm
  public utm_campaign?: string;
  public utm_content?: string;
  public utm_id?: string;
  public utm_medium?: string;
  public utm_source?: string;
  public utm_term?: string;
  public utm_test?: string;

  public constructor(source: Partial<UtmModel> = {}) {
    merge(this, cloneDeep(pickBy(source, Boolean)));
  }
}

export { UtmModel };
