import { UrlFragments } from "../types";

import { templateUrl } from "~/helpers/template";
import { Fetch, FetchService } from "~/services/fetch/Fetch";

abstract class ApiService<Url extends string> {
  protected readonly PREFIX: string = "api";
  protected readonly SUFFIX: string = "";
  protected readonly VERSION: string = "v2";
  protected readonly urlSchema: string = "/{{ prefix }}/{{ version }}/{{ suffix }}";

  protected process: Record<string, Promise<any>> = {};

  protected get fetch(): Fetch {
    return FetchService;
  }

  protected buildUrl(url: Url, urlFragments: UrlFragments = {}): string {
    const urlTemplate: string = this.buildUrlTemplate(url);
    const urlTemplateData: UrlFragments = this.buildUrlTemplateData(urlFragments);

    return templateUrl(urlTemplate, urlTemplateData);
  }

  protected buildUrlTemplate(url: Url): string {
    return `${this.urlSchema}/${url}`;
  }

  protected buildUrlTemplateData(urlFragments: UrlFragments = {}): UrlFragments {
    return {
      prefix: this.PREFIX,
      suffix: this.SUFFIX,
      version: this.VERSION,
      ...urlFragments,
    };
  }
}

export { ApiService };
