import { sha256 } from "hash-wasm";
import { IdentifyModel } from "./models";
import { EventName, Payload } from "./types";
import { Task } from "~/services/taskDeprecated/Task";
import { TaskQueue } from "~/services/taskDeprecated/TaskQueue";

class Reddit extends TaskQueue {
  public static REDDIT_KEYWORD: string = "reddit";
  public static REDDIT_PIXEL_ID: string = process.env.CX_REDDIT_PIXEL_ID;
  public static TTL: number = 10000;

  private isInitialized: boolean = false;

  public get rdt(): any {
    return window?.rdt;
  }

  public get isEnabled(): boolean {
    return !!(Reddit.REDDIT_PIXEL_ID && this.rdt && this.rdt?.sendEvent);
  }

  /**
   * Important Note: All ‘Advanced Matching’ parameters are set once on pixel initialization. This means that advanced parameter values must be passed on the first event of the page. If those values are not passed on the first event, then they’ll never be passed on that page.
   * @param {IdentifyModel} identifyModel
   */
  public init(identifyModel: IdentifyModel = new IdentifyModel()): void {
    if (this.isEnabled && this.isInitialized) {
      this.overrideConfig(identifyModel);
      return;
    }

    const task: Task = new Task((identifyModel: IdentifyModel) => {
      this.rdt("init", Reddit.REDDIT_PIXEL_ID, identifyModel);
    })
      .setTtl(Reddit.TTL)
      .setArguments([identifyModel])
      .setContext(RedditService);

    this.isInitialized = true;

    this.execute(task, true);
  }

  public page(): void {
    this.track(EventName.PAGE_VISIT);
  }

  public track(eventName: EventName, payload?: Payload): void {
    const task: Task = new Task((eventName: EventName, payload?: Payload): void => {
      this.rdt("track", eventName, payload);
    })
      .setTtl(Reddit.TTL)
      .setArguments([eventName, payload])
      .setContext(RedditService);

    this.execute(task);
  }

  private overrideConfig(identifyModel: IdentifyModel): void {
    if (!identifyModel) {
      return;
    }

    const task: Task = new Task(async (identifyModel: IdentifyModel): Promise<void> => {
      this.rdt.config.external_id = identifyModel.externalId
        ? await sha256(identifyModel.externalId)
        : this.rdt.config.external_id;

      this.rdt.config.em = identifyModel.email
        ? await sha256(this.normalizeEmail(identifyModel.email))
        : this.rdt.config.em;
    })
      .setTtl(Reddit.TTL)
      .setArguments([identifyModel])
      .setContext(RedditService);

    this.execute(task);
  }

  private execute(task: Task, prepend: boolean = false): void {
    (prepend && this.prependTask(task)) || this.addTask(task);

    if (this.isEnabled && this.isInitialized) {
      this.executeTasks();
    }
  }

  /** Reddit forces to use normalize email address for advance matching: base.Local+extendLocal@domain.com -> baselocal@domain.com */
  private normalizeEmail(email: string): string {
    const [localPart, domainPart] = email.toLowerCase().split("@");
    const [baseLocalPart] = localPart.split("+");
    const normalizeLocalPart = baseLocalPart.replace(/\./gm, "");

    return `${normalizeLocalPart}@${domainPart}`;
  }
}

const RedditService: Reddit = new Reddit();

export { RedditService, Reddit };
