import parseAsString from "lodash/toString";

import { LoggerService } from "~/services/logger";

const getFromQuery = (parameter: string, search: string = window.location.search.substring(1)): string => {
  if (!process.browser || !window || !document) {
    return "";
  }

  try {
    const params: URLSearchParams = new URLSearchParams(search);
    return parseAsString(
      new URLSearchParams([...params].map(([key, value]) => [key.toLowerCase(), value])).get(parameter.toLowerCase()),
    );
  } catch (error) {
    LoggerService.error(error);
  }

  return "";
};

export { getFromQuery };
