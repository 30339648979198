import { PatientModel, SaveUtmData, UserModel } from "./models";
import { Url } from "./types";

import { ApiService } from "~/services/Api/abstracts";

import { FetchService } from "~/services/fetch/Fetch";
import { RequestConfigModel } from "~/services/fetch/models";
import { VisitModel } from "~/services/QueryStorage/models";

export class ApiUser extends ApiService<Url> {
  public async get(): Promise<UserModel> {
    return await FetchService.getJson<UserModel>(
      this.buildUrl(Url.USER),
      null,
      new RequestConfigModel({ model: new UserModel({ patient: new PatientModel() }) }),
    );
  }

  public async saveUtm(utmData: VisitModel[]): Promise<SaveUtmData> {
    return await FetchService.postJson<SaveUtmData>(
      this.buildUrl(Url.USER_UTM_DATA),
      new SaveUtmData({ utmDataArray: utmData }),
      new RequestConfigModel(),
    );
  }

  public async createPatient(): Promise<UserModel["patient"]> {
    return await FetchService.postJson<UserModel["patient"]>(this.buildUrl(Url.CREATE_PATIENT, { version: "v3" }));
  }
}

const ApiUserService: ApiUser = new ApiUser();

export { ApiUserService };
