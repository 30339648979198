import isEmpty from "lodash/isEmpty";

import { TemplateOptions } from "./types";
import { templateOptionsDefault } from "./defaults";
import { compileTemplate } from "./compileTemplate";
import { templateError } from "./errors";
import { SentryService } from "~/services/sentry/SentryService";

const template = (
  content: string,
  data?: Record<string, any>,
  templateOptions: TemplateOptions = templateOptionsDefault
): string => {
  try {
    if (isEmpty(data)) {
      return content;
    }

    return compileTemplate(content, data);
  } catch (error) {
    if (!templateOptions.skipSentryError) {
      SentryService.report(
        templateError(error, "template")
          .addExtra("content", content)
          .addExtra("data", data)
          .addExtra("templateOptions", templateOptions as any)
      );
    }
  }
};

export { template };
