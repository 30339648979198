import merge from "lodash/merge";
import { ErrorInfoModel, ErrorModel } from "../models";
import { ErrorType } from "../types";

const parseErrorFromBackend = (responseJson: unknown): ErrorModel => {
  // @ts-ignore
  const responseJsonData = responseJson?.data || {};

  // @ts-ignore
  Object.entries(responseJsonData?.extra || {}).forEach(([key, value]: [string, string]) => {
    responseJsonData.extra[key] = String(value);
  });

  const dataFromJson: ErrorInfoModel = merge(new ErrorInfoModel(), responseJsonData);
  return new ErrorModel({ type: ErrorType.BACKEND, data: dataFromJson });
};

export { parseErrorFromBackend };
