import { PageModel } from "./PageModel";

class RuleModel {
  public destination: string = ""; // Path to page if conditions were met
  public sources: string[] = []; // Paths from original pages where client were redirected form

  public interceptors: Array<(currentPage: PageModel, newPage: PageModel) => PageModel> = [];

  public constructor(rule: Partial<RuleModel> = {}) {
    Object.assign(this, rule);
  }
}

export { RuleModel };
