import { ErrorModel } from "./models";
import { Context, Contexts, Extras, RequestSession, SentryExtra, SentryTagKey, SentryTagValue } from "./types";

import { LoggerService } from "~/services/logger";

import { ErrorModel as FetchErrorModel } from "~/services/fetch/models";

class SentryError extends Error {
  private static _NAME: string = "SentryError";
  private static _MESSAGE: string = "An Error Occurred";
  public responseError: FetchErrorModel | void = null;
  private _error: ErrorModel | void = null;
  private _extras: Extras = {};
  private _fingerprints: string[] = [];
  private _requestSession: RequestSession = {};
  private _tags: Record<SentryTagKey, SentryTagValue> = {} as Record<SentryTagKey, SentryTagValue>;

  public constructor(
    public name: string = SentryError._NAME,
    public message: string = SentryError._MESSAGE,
    ...params: any
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SentryError);
    }
  }

  public get tags(): SentryError["_tags"] {
    return this._tags;
  }

  public get fingerprints(): SentryError["_fingerprints"] {
    return this._fingerprints;
  }

  public get requestSession(): SentryError["_requestSession"] {
    return this._requestSession;
  }

  public get extras(): Extras {
    return this._extras as unknown as Extras;
  }

  public get contexts(): Contexts {
    const contexts: Contexts = {};

    if (this._error) {
      contexts.error = this._error as unknown as Context;
    }

    if (this.responseError) {
      contexts.responseError = this.responseError as unknown as Context;
    }

    return contexts;
  }

  public setName(name: string = SentryError._NAME): SentryError {
    this.name = name;
    return this;
  }

  public setMessage(message: string = SentryError._MESSAGE): SentryError {
    this.message = message;
    return this;
  }

  public setResponse(error: FetchErrorModel): SentryError {
    if (error instanceof FetchErrorModel) {
      this.responseError = error;
    } else {
      LoggerService.warn("Attempted to set not fetch error to sentry fetch error. ", error);
    }

    return this;
  }

  public setError(error: unknown | Error): SentryError {
    if (error instanceof Error) {
      this._error = new ErrorModel(error?.name, error?.message);
    } else {
      this._error = new ErrorModel("Empty error reported.");
      LoggerService.warn("Attempted to report empty error to sentry. Error created with this description instead.");
    }

    return this;
  }

  public addExtra(key: string, value: SentryExtra): SentryError {
    this._extras[key] = value;
    return this;
  }

  public addTag(key: SentryTagKey, value: SentryTagValue): SentryError {
    this._tags[key] = value;
    return this;
  }

  public addFingerprint(fingerprint: string): SentryError {
    this._fingerprints.push(fingerprint);
    return this;
  }

  public setRequestSession(requestSession: SentryError["_requestSession"]): SentryError {
    this._requestSession = requestSession;
    return this;
  }
}

export { SentryError };
