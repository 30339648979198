import { LoggerService } from "~/services/logger";

import { UserModel } from "~/services/ApiUser/models";
import { ApiUserService } from "~/services/ApiUser";

const getUser = async (): Promise<UserModel | void> => {
  try {
    return await ApiUserService.get();
  } catch (e) {
    LoggerService.error("Failed to get user.");
  }
};

export { getUser };
