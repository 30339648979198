import { VuexModule } from "nuxt-property-decorator";
import { Store } from "vuex";

export abstract class Repository<T extends VuexModule> {
  public static STORE: Store<any>;

  public get store(): Store<any> {
    return Repository.STORE;
  }

  public abstract get module(): T;
}
