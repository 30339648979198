import { Module, VuexModule, VuexMutation } from "nuxt-property-decorator";

import { VersionModel } from "~/modules/revision/models";
import { DEVELOPMENT_VERSION } from "~/modules/revision/constants";
import { AnalyticsService } from "~/services/analytics/Analytics";

@Module({
  name: "miscellaneous/revision",
  stateFactory: true,
  namespaced: true,
})
class RevisionStore extends VuexModule {
  private _currentRevision: VersionModel = new VersionModel();

  public get currentRevision(): VersionModel {
    return this._currentRevision;
  }

  private _futureRevision: VersionModel = new VersionModel();

  public get futureRevision(): VersionModel {
    return this._futureRevision;
  }

  public get isAppOutdated(): boolean {
    if (!this._currentRevision.commitHead.length || !this._futureRevision.commitHead.length) {
      return false;
    }

    if (
      this._currentRevision.commitHead === DEVELOPMENT_VERSION ||
      this._futureRevision.commitHead === DEVELOPMENT_VERSION
    ) {
      return false;
    }

    return this._currentRevision.commitHead !== this._futureRevision.commitHead;
  }

  @VuexMutation
  public setCurrentRevision(revision: VersionModel): void {
    this._currentRevision = revision;

    AnalyticsService.updateContext({
      app: {
        name: "Patient Portal",
        version: revision.commitHead,
        build: `${revision.buildTimestamp}`,
      },
    });
  }

  @VuexMutation
  public setFutureRevision(revision: VersionModel): void {
    this._futureRevision = revision;
  }
}

export default RevisionStore;
