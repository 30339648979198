import isString from "lodash/isString";

import { VisitModel } from "../models";
import { isUtmModelEmpty } from "./isUtmModelEmpty";

import { isObject } from "~/utils";

// utm tags object must not be empty or referer should not be empty
const isVisitModelEmpty = (model: VisitModel): model is VisitModel => {
  const isUtmTagsSet: boolean = isObject(model?.data) && !isUtmModelEmpty(model?.data);
  const isRefererSet: boolean = isString(model?.referer) && !!model.referer.length;

  return !isUtmTagsSet && !isRefererSet;
};

export { isVisitModelEmpty };
