import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";

import type { UserTraits } from "@segment/analytics-core";
import type { EventPayload } from "../../../types";

// https://segment.com/docs/connections/spec/identify/#traits
const eventPayloadToUserData = (eventPayload?: EventPayload | void): UserTraits => {
  if (!eventPayload || isNil(eventPayload)) {
    return {} as UserTraits;
  }

  const result: UserTraits = {
    address: eventPayload?.traits?.address,
    age: eventPayload?.traits?.age,
    birthday: eventPayload?.traits?.birthday,
    createdAt: eventPayload?.traits?.createdAt,
    email: eventPayload?.traits?.email,
    firstName: eventPayload?.traits?.firstName,
    gender:
      eventPayload?.traits?.gender === "female" || eventPayload?.traits?.gender === "male"
        ? eventPayload?.traits?.gender
        : undefined,
    id: eventPayload?.traits?.id,
    lastName: eventPayload?.traits?.lastName,
    phone: eventPayload?.traits?.phone,
  };

  return pickBy(result, (value: string | undefined) => value !== undefined);
};

export { eventPayloadToUserData };
