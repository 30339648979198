import { LoggerService } from "~/services/logger";
import { isScriptLoaded, loadScript } from "~/utils";

declare global {
  interface Window {
    ttq?: any;
    TiktokAnalyticsObject?: "ttq";
  }
}

const scriptLoader = (pixelId: string, callback: () => void = (): void => {}): void => {
  /**
   * Code below is default TikTok pixel loader from https://ads.tiktok.com/help/article?aid=10021
   */
  window.TiktokAnalyticsObject = "ttq";

  const ttq = (window.ttq = window?.ttq || []);

  ttq.methods = [
    "page",
    "track",
    "identify",
    "instances",
    "debug",
    "on",
    "off",
    "once",
    "ready",
    "alias",
    "group",
    "enableCookie",
    "disableCookie",
  ];

  ttq.setAndDefer = function (ttqInst: typeof ttq, method: string) {
    ttqInst[method] = function () {
      ttqInst.push([method].concat(Array.prototype.slice.call(arguments, 0)));
    };
  };

  ttq.methods.forEach((method: string): void => {
    ttq.setAndDefer(ttq, method);
  });

  ttq.instance = function (ttqId: string) {
    const ttqInstance = ttq._i[ttqId] || [];

    ttq.methods.forEach((method: string): void => {
      ttq.setAndDefer(ttqInstance, method);
    });

    return ttqInstance;
  };

  ttq.load = function (pixelId: string, n?: any): void {
    const tikTokPixelUrl: string = "https://analytics.tiktok.com/i18n/pixel/events.js";
    ttq._i = ttq._i || {};
    ttq._i[pixelId] = [];
    ttq._i[pixelId]._u = tikTokPixelUrl;
    ttq._t = ttq._t || {};
    ttq._t[pixelId] = +new Date();
    ttq._o = ttq._o || {};
    ttq._o[pixelId] = n || {};

    const scriptUrl: string = `${tikTokPixelUrl}?sdkid=${pixelId}&lib=ttq`;

    if (isScriptLoaded(scriptUrl)) {
      LoggerService.warn("TikTok pixel script is already loaded.", tikTokPixelUrl);
      callback();
      return;
    }

    loadScript(scriptUrl, callback);
  };

  ttq.load(pixelId);
};

const loadTikTokPixel = (pixelId: string, callback?: () => void) => {
  if (!pixelId?.length) {
    LoggerService.error("TikTok pixel id is missing.", pixelId);
    return;
  }

  scriptLoader(pixelId, callback);
};

export { loadTikTokPixel };
