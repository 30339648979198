import isString from "lodash/isString";
import parseAsString from "lodash/toString";

import { isObject } from "~/utils/validation/isObject";

// todo: remove this
const isJson = (item: any): item is object | string => {
  let copyItem: string = !isString(item) ? JSON.stringify(item) : parseAsString(item);
  try {
    copyItem = JSON.parse(copyItem);
  } catch (error) {
    return false;
  }

  return isObject(copyItem);
};

export { isJson };
