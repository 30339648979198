// Used to display readable hints upon validation
enum Field {
  EMAIL = "email-address",
  CURRENT_PASSWORD = "current-password",
  NEW_PASSWORD = "new-password",
  NEW_PASSWORD_CONFIRM = "repeat-new-password",
  TERMS_OF_SERVICE = "terms-of-service-agreement",
}

export { Field };
