enum InterceptorCategory {
  APP_INFO = "app-info",
  AUTHORIZATION = "authorization",
  CSRF_TOKEN = "csrf-token", // todo: implement
  DEV_PROXY = "dev-proxy", // todo: implement
  JSON_FORMATTER = "json-formatter",
  NO_CACHE = "no-cache",
  QUERY_INFO = "query-info",
  REDIRECT_ON_AUTH_FAIL = "redirect-on-auth-fail", // todo: remove in the future
  TIMEOUT = "timeout",
  ERROR = "error",
}

export { InterceptorCategory };
