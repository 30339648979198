import { LoggerService } from "~/services/logger";

const isScriptLoaded = (urlPart: string): boolean => {
  try {
    return !!document.querySelectorAll(`script[src*="${urlPart}"]`).length;
  } catch (e) {
    LoggerService.error("isScriptLoaded failed to check execute:", e);
    return false;
  }
};

export { isScriptLoaded };
