// temp hack: should be able to extract colors from scss
import { Color } from "../types";

const colorHexValues: Record<Color, string> = {
  [Color.BEIGE_100]: "#FAF1DC",

  [Color.BLUE_100]: "#F0F8FF",
  [Color.BLUE_150]: "#CBEAFF",
  [Color.BLUE_200]: "#A1D9FF",
  [Color.BLUE_300]: "#57BBFF",
  [Color.BLUE_400]: "#0A9CFF",
  [Color.BLUE_500]: "#0573BE",

  [Color.PEACH_50]: "#FEF8F4",

  [Color.PINK_100]: "#FFB4D2",
  [Color.PINK_200]: "#FF80B3",
  [Color.PINK_300]: "#FF5C9E",
  [Color.PINK_400]: "#BD4274",

  [Color.YELLOW_100]: "#FFFFD3",
  [Color.YELLOW_200]: "#FFFF9B",
  [Color.YELLOW_300]: "#FFE780",
  [Color.YELLOW_500]: "#FF3",

  [Color.GREEN_100]: "#83EEA9",
  [Color.GREEN_200]: "#4ADC7E",
  [Color.GREEN_300]: "#0DCE52",
  [Color.GREEN_400]: "#28a745",
  [Color.GREEN_500]: "#058130",

  [Color.BLACK]: "#000",

  [Color.GRAY_50]: "#f7f8fa",
  [Color.GRAY_75]: "#f1f4f5",
  [Color.GRAY_100]: "#ecf2f5",
  [Color.GRAY_200]: "#cdd0d8",
  [Color.GRAY_300]: "#c0c2ca",
  [Color.GRAY_500]: "#40535e",
  [Color.GRAY_600]: "#40424b",
  [Color.GRAY_700]: "#262f35",

  [Color.BROWN_200]: "#ca9376",

  [Color.WHITE]: "#fff",

  [Color.TRANSPARENT]: "transparent",
};

const getHexColor = (color: Color) => {
  return colorHexValues[color];
};

export { getHexColor };
