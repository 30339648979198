import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";

import type { EventPayload } from "../../../types";
import type { MetaUserData } from "../types";

import { removeAllButNumbers } from "~/utils/converters/removeAllButNumbers";

const eventPayloadToUserData = (eventPayload?: EventPayload | void): MetaUserData => {
  if (!eventPayload || isNil(eventPayload)) {
    return {};
  }

  const em: string | void = eventPayload?.traits?.email?.toLowerCase() || undefined;
  const fn: string | void = eventPayload?.traits?.firstName?.trim()?.toLowerCase() || undefined;
  const ln: string | void = eventPayload?.traits?.lastName?.trim()?.toLowerCase() || undefined;
  const ph: string | void = removeAllButNumbers(eventPayload?.traits?.phone) || undefined;

  // eslint-disable-next-line camelcase
  const external_id: string | void = eventPayload?.userId || undefined;

  const ge: string | void =
    eventPayload?.traits?.gender === "female" ? "f" : eventPayload?.traits?.gender === "male" ? "m" : undefined;

  const db: string | void = removeAllButNumbers(eventPayload?.traits?.birthday) || undefined;
  const ct: string | void = eventPayload?.traits?.address?.city?.toLowerCase()?.replace(/\s/g, "") || undefined;
  const st: string | void = eventPayload?.traits?.address?.state?.trim()?.toLowerCase() || undefined;
  const zp: string | void = eventPayload?.traits?.address?.postalCode?.trim()?.substring(0, 5) || undefined;
  const country: "us" | void = st?.length ? "us" : undefined;

  const result: MetaUserData = {
    em,
    fn,
    ln,
    ph,
    // eslint-disable-next-line camelcase
    external_id,
    ge,
    db,
    ct,
    st,
    zp,
    country,
  };

  return pickBy(result, (value: string | undefined) => value !== undefined);
};

export { eventPayloadToUserData };
