/* eslint-disable */
// @ts-nocheck

import { loadScript } from "~/utils/helpers/loadScript";

declare global {
  interface Window {
    dataLayer: any;
  }
}

// consent doc: https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#gtag.js_1
const loadPixel = (id: string): Promise<void> => {
  window.dataLayer = window?.dataLayer || [];

  // config doc: https://developers.google.com/tag-platform/gtagjs/reference
  window.gtag =
    window.gtag ||
    function () {
      window.dataLayer.push(arguments);
    };

  // https://developers.google.com/tag-platform/security/concepts/consent-mode#consent-types
  gtag("consent", "default", {
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted",
    functionality_storage: "granted",
    personalization_storage: "granted",
    security_storage: "granted",
  });

  // Seems like every set should be used before js and config command
  // seems like order of execution matters, at least when looking through tag debugger

  gtag("set", "cookie_flags", "SameSite=None;Secure");

  // https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#passthroughs
  gtag("set", "url_passthrough", true);

  // https://developers.google.com/tag-platform/devguides/cross-domain#linker_parameter_reference
  gtag("set", "linker", { accept_incoming: true, domains: ["getcurex.com", "stripe.com", "checkout.stripe.com"] });

  gtag("set", "allow_google_signals", true); // advertising features
  gtag("set", "allow_ad_personalization_signals", true); // advertising personalization features

  // Set to true to indicate to Analytics that the referrer shouldn't be displayed as a traffic source.
  // gtag("set", "ignore_referrer", true);

  // adds rdp=0 to google ads request

  gtag("set", "restricted_data_processing", false);

  gtag("set", "send_page_view", false);

  gtag("js", new Date());
  gtag("config", id, {
    send_page_view: false,
  });

  return new Promise((resolve, reject) => {
    try {
      loadScript(`https://www.googletagmanager.com/gtm.js?id=${id}`, () => {
        resolve();
      });
    } catch (error: unknown) {
      reject(error);
    }
  });
};

export { loadPixel };
