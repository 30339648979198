// todo: using this method for error parsing is unreliable
import get from "lodash/get";

const mapKeys: <T, K extends Partial<Record<keyof T, string>>>(object: T, map: K) => T & K = <
  T,
  K extends Partial<Record<keyof T, string>>
>(
  object: T,
  map: K
): T & K => {
  const mappedObject: any = {};

  Object.entries<string>(map).forEach(([key, newKey]) => {
    mappedObject[newKey] = get(object, key);
  });

  return Object.assign(object, mappedObject);
};

export { mapKeys };
