import { DEVELOPMENT_VERSION } from "../constants";

const getGitVersion = (): string => {
  return (
    process.env.commitHead ||
    process.env.NUXT_ENV_VERCEL_GIT_COMMIT_SHA ||
    process.env.VERCEL_GIT_COMMIT_SHA ||
    DEVELOPMENT_VERSION
  );
};

export { getGitVersion };
