import parseAsString from "lodash/toString";

const formatAsUrlSearchParams = (source: string): URLSearchParams => {
  try {
    const formattedSource: string = parseAsString(source);

    let result: string;
    const hasQuestionMark: boolean = formattedSource.lastIndexOf("?") !== -1;
    if (hasQuestionMark) {
      result = formattedSource.slice(source.lastIndexOf("?"));
    } else {
      result = formattedSource;
    }

    return new URLSearchParams(result);
  } catch (error) {
    console.error("toUrlSearchParams", error);
  }

  return new URLSearchParams();
};

export { formatAsUrlSearchParams };
