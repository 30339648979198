const getUrlPath = (url: string): string => {
  try {
    return new URL(url).pathname;
  } catch (error) {
    console.error("getUrlPath", error);
  }

  return url;
};

export { getUrlPath };
