import parseAsString from "lodash/toString";

import type { StorageService } from "../Storage";

import { getFromQuery } from "~/utils/parsers/getFromQuery";

const saveReferral = (storageService: typeof StorageService): void => {
  const currentReferrer: string = getFromQuery("referral");
  if (currentReferrer.length) {
    storageService.set("referral", currentReferrer);
    return;
  }

  const referrer: string = parseAsString(document?.referrer);
  const referrerValidAndHasSearchString: boolean =
    referrer.includes("getcurex.com?") || referrer.includes("getcurex.com/?");

  if (!referrerValidAndHasSearchString) {
    return;
  }

  const searchString: string = parseAsString(referrer.split("?")?.[1]);

  const referrerFromReferrer: string = getFromQuery("referral", searchString);
  if (!referrerFromReferrer.length) {
    return;
  }

  storageService.set("referral", referrerFromReferrer);
};

export { saveReferral };
