import { ErrorName } from "../types";
import { ErrorModel } from "../models";
import { parseDataAsSentryExtras } from "../helpers";
import { fetchInterceptorErrorOptions } from "../defaults";

import { SentryError } from "~/services/sentry/SentryError";
import { Extras, SentryScope, SentryTagKey } from "~/services/sentry/types";

const redirectResponseError = (error: ErrorModel, data?: any): SentryError => {
  const sentryError: SentryError = new SentryError()
    .setName(ErrorName.AUTHORIZATION_FAILED)
    .setMessage("Request was declined because token is invalid. Redirected.")
    .setError(new Error(ErrorName.AUTHORIZATION_FAILED, fetchInterceptorErrorOptions))
    .setResponse(error)
    .addTag(SentryTagKey.SCOPE, SentryScope.FETCH)
    .addFingerprint("Fetch.redirectResponseError");

  const extras: Extras = parseDataAsSentryExtras(data);

  for (const key in extras) {
    sentryError.addExtra(key, extras[key] as any);
  }

  return sentryError;
};

export { redirectResponseError };
