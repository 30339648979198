enum EventName {
  ADD_TO_CART = "AddToCart",
  ADD_TO_WISHLIST = "AddToWishlist",
  CUSTOM = "Custom",
  LEAD = "Lead",
  PAGE_VISIT = "PageVisit",
  PURCHASE = "Purchase",
  SEARCH = "Search",
  SIGNUP = "SignUp",
  VIEW_CONTENT = "ViewContent",
}

export { EventName };
