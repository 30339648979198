import isNil from "lodash/isNil";

const isCreatedDateExact = (date: Date, day: number, month: number, year: number): boolean => {
  if (isNil(date) || Object.prototype.toString.call(date) !== "[object Date]" || isNaN(date.getTime())) {
    return false;
  }

  return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
};

export { isCreatedDateExact };
