import parseAsString from "lodash/toString";

import { compress, decompress } from "lz-string";

import { SaveFormatModel } from "../models/SaveFormatModel";

const fromBinary = (binary: string): string => {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }

  const charCodes = new Uint16Array(bytes.buffer);

  let result: string = "";
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }

  return result;
};

abstract class StorageStrategy {
  public abstract get<U>(key: string, format?: "v1" | "v2"): U;

  public abstract has(key: string): boolean;

  public abstract remove(key: string): StorageStrategy;

  public abstract set<U>(key: string, data: U): StorageStrategy;

  protected _formatDataOnGetWithOldEncoding<U>(rawData: string): U {
    try {
      const { data = {} as U }: SaveFormatModel<U> = JSON.parse(fromBinary(atob(parseAsString(rawData))));

      return data;
    } catch (e: unknown) {
      console.error("StorageStrategy._formatDataOnGetWithOldEncoding error", e);
    }
  }

  protected formatDataOnGet<U>(rawData: string): U {
    try {
      const { data = {} as U }: SaveFormatModel<U> = JSON.parse(decompress(parseAsString(rawData)));

      return data;
    } catch (e: unknown) {
      console.error("StorageStrategy.formatDataOnGet error", e);
    }
  }

  protected formatDataOnSet<U>(data: U): string {
    try {
      return compress(JSON.stringify(new SaveFormatModel({ data })));
    } catch (e: unknown) {
      console.error("StorageStrategy.formatDataOnSet error", e);
    }

    return "";
  }
}

export { StorageStrategy };
