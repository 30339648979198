import parseAsString from "lodash/toString";
import parseAsNumber from "lodash/toNumber";

import type { EventPayload } from "../../../types";
import type {
  BeginCheckoutEvent,
  CheckoutProgressEvent,
  PurchaseEvent,
  SignUpEvent,
  UETEvent,
  UETEventProperties,
  ViewPromotionEvent,
} from "../types";

import { UETEventName } from "../types/UETEventName";
import { UETEventType } from "../types/UETEvent";

import { AnalyticsEventNames } from "~/mixins/demographics/types/analyticsEventNames";

const getCoupon = (eventPayload?: EventPayload): string => parseAsString(eventPayload?.context?.campaign?.coupon);

const quizItem: UETEventProperties["items"][0] = {
  id: "quiz",
  price: 49,
  quantity: 1,
};

const quizProduct: UETEventProperties = {
  items: [quizItem],
  currency: "USD",
  revenue_value: 10.0,
};

const beginCheckoutEventProperties = (eventPayload?: EventPayload): BeginCheckoutEvent["properties"] => ({
  ...(quizProduct as BeginCheckoutEvent["properties"]),
  coupon: getCoupon(eventPayload),
});

const signUpEventProperties = (eventPayload?: EventPayload): SignUpEvent["properties"] => ({
  method: eventPayload?.properties?.method || "passwordless",
  currency: "USD",
  revenue_value: 15.0,
});

const viewPromotionEventProperties = (): ViewPromotionEvent["properties"] => ({
  currency: "USD",
  revenue_value: 20.0,
  promotions: [
    {
      creative_name: "Limited offer",
      creative_slot: "page",
      id: "1",
      name: "Limited offer",
    },
  ],
});

const checkoutProgressEventProperties = (eventPayload?: EventPayload): CheckoutProgressEvent["properties"] => {
  const step: number = Math.ceil(parseAsNumber(parseAsString(eventPayload?.properties?.progress)));

  return {
    ...(quizProduct as CheckoutProgressEvent["properties"]),
    coupon: parseAsString(eventPayload?.context?.campaign?.coupon),
    checkout_step: step,
    checkout_option: "credit-card",
    revenue_value: 11.0 + step,
  };
};

const purchaseEventProperties = (eventPayload?: EventPayload): PurchaseEvent["properties"] => ({
  ...(quizProduct as PurchaseEvent["properties"]),
  tax: 1,
  shipping: 1,
  coupon: parseAsString(eventPayload?.context?.campaign?.coupon),
  revenue_value: 49.0,
  transaction_id: eventPayload?.properties?.sessionId || (Math.random() + 1).toString(36).substring(7),
});

const eventPayloadToUETEvent = (event: string, eventPayload?: EventPayload): UETEvent[] => {
  const result: UETEvent[] = [{ type: UETEventType.EVENT, name: event, properties: {} }];

  switch (event) {
    case AnalyticsEventNames.CHECKOUT_SESSION_CREATED: {
      result.push({
        type: UETEventType.EVENT,
        name: UETEventName.BEGIN_CHECKOUT,
        properties: beginCheckoutEventProperties(eventPayload),
      });

      break;
    }

    case AnalyticsEventNames.CHECKOUT_USER_SIGNED_UP: {
      result.push({
        type: UETEventType.EVENT,
        name: UETEventName.SIGN_UP,
        properties: signUpEventProperties(eventPayload),
      });

      break;
    }

    case "Question Viewed": {
      result.push({
        type: UETEventType.EVENT,
        name: UETEventName.CHECKOUT_PROGRESS,
        properties: checkoutProgressEventProperties(eventPayload),
      });

      switch (eventPayload?.properties?.key) {
        case "pqokjrjhbc": {
          // limited offer
          result.push({
            type: UETEventType.EVENT,
            name: UETEventName.VIEW_PROMOTION,
            properties: viewPromotionEventProperties(),
          });

          break;
        }
      }

      break;
    }

    case AnalyticsEventNames.CHECKOUT_SESSION_COMPLETED:
      result.push({
        type: UETEventType.EVENT,
        name: UETEventName.PURCHASE,
        properties: purchaseEventProperties(eventPayload),
      });

      break;
  }

  return result;
};

export { eventPayloadToUETEvent };
