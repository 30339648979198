import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

export class IdentifyModel {
  public email?: string;
  public externalId?: string;
  public optOut?: boolean = false;
  public useDecimalCurrencyValues?: boolean = true;

  constructor(identify: Partial<IdentifyModel> = {}) {
    merge(this, cloneDeep(identify));
  }
}
