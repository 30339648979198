import type { EventPayload } from "../../../types";
import type {
  CompleteRegistrationEvent,
  InitiateCheckoutEvent,
  LeadEvent,
  MetaEvent,
  MetaEventProperties,
  PurchaseEvent,
  ScheduleEvent,
  SubscribeEvent,
  ViewContentEvent,
} from "../types";

import { MetaEventType } from "../types/metaEvent";
import { MetaEventName } from "../types/metaEventName";

import { AnalyticsEventNames } from "~/mixins/demographics/types/analyticsEventNames";

const getContents = (eventPayload?: EventPayload) => [
  { id: eventPayload?.properties?.version || "checkout", quantity: 1 },
];

const quizProduct: MetaEventProperties = {
  content_category: "checkout",
  contents: getContents(),
  content_name: "Quiz",
  content_type: "product_group",
  currency: "USD",
  value: 20.0,
};

const initiateCheckoutEventProperties = (eventPayload?: EventPayload): InitiateCheckoutEvent["properties"] => ({
  ...(quizProduct as InitiateCheckoutEvent["properties"]),
  contents: getContents(eventPayload),
  num_items: 1,
  value: 10.0,
});

const leadEventProperties = (eventPayload?: EventPayload): LeadEvent["properties"] => ({
  ...(quizProduct as LeadEvent["properties"]),
  contents: getContents(eventPayload),
  value: 30.0,
});

const completeRegistrationEventProperties = (eventPayload?: EventPayload): CompleteRegistrationEvent["properties"] => ({
  ...(quizProduct as CompleteRegistrationEvent["properties"]),
  contents: getContents(eventPayload),
  status: true,
  value: 40.0,
});

const viewContentEventProperties = (eventPayload?: EventPayload): ViewContentEvent["properties"] => ({
  ...(quizProduct as ViewContentEvent["properties"]),
  contents: getContents(eventPayload),
  value: 1.0,
});

const purchaseEventProperties = (eventPayload?: EventPayload): PurchaseEvent["properties"] => ({
  ...(quizProduct as PurchaseEvent["properties"]),
  num_items: 1,
  contents: getContents(eventPayload),
  value: 100.0,
  order_id: eventPayload?.properties?.sessionId || undefined,
});

const subscribeEventProperties = (eventPayload?: EventPayload): SubscribeEvent["properties"] => ({
  currency: "USD",
  predicted_ltv: 1000.0,
  contents: getContents(eventPayload),
  value: 200.0,
});

const scheduleEventProperties = (): ScheduleEvent["properties"] => ({
  currency: "USD",
  value: 60.0,
});

const eventPayloadToMetaTrackEvent = (event: string, eventPayload?: EventPayload): MetaEvent[] => {
  const result: MetaEvent[] = [{ name: event, type: MetaEventType.TRACK_CUSTOM, properties: {} }];

  switch (event) {
    case AnalyticsEventNames.CHECKOUT_SESSION_CREATED: {
      result.push({
        type: MetaEventType.TRACK,
        name: MetaEventName.INITIATE_CHECKOUT,
        properties: initiateCheckoutEventProperties(eventPayload),
      });

      break;
    }

    case AnalyticsEventNames.CHECKOUT_USER_SIGNED_UP: {
      result.push({
        type: MetaEventType.TRACK,
        name: MetaEventName.LEAD,
        properties: leadEventProperties(eventPayload),
      });

      break;
    }

    case "Question Viewed": {
      switch (eventPayload?.properties?.key) {
        case "pqokjrjhbc": {
          // limited offer
          result.push({
            type: MetaEventType.TRACK,
            name: MetaEventName.VIEW_CONTENT,
            properties: viewContentEventProperties(eventPayload),
          });

          break;
        }

        case "lhdyqmbenx": {
          // 1st available page after patient created
          result.push({
            type: MetaEventType.TRACK,
            name: MetaEventName.COMPLETE_REGISTRATION,
            properties: completeRegistrationEventProperties(eventPayload),
          });

          break;
        }
      }

      break;
    }

    case AnalyticsEventNames.CHECKOUT_SESSION_COMPLETED: {
      result.push({
        type: MetaEventType.TRACK,
        name: MetaEventName.PURCHASE,
        properties: purchaseEventProperties(eventPayload),
      });

      break;
    }

    case AnalyticsEventNames.SESSION_COMPLETED: {
      if (!eventPayload?.properties?.version?.length || !eventPayload.properties.version?.includes("medical-history")) {
        break;
      }

      result.push({
        type: MetaEventType.TRACK,
        name: MetaEventName.SUBSCRIBE,
        properties: subscribeEventProperties(eventPayload),
      });

      break;
    }

    case "Office Hours - Join Now":
    case "Office Hours - Schedule": {
      result.push({
        type: MetaEventType.TRACK,
        name: MetaEventName.SCHEDULE,
        properties: scheduleEventProperties(),
      });

      break;
    }
  }

  return result;
};

export { eventPayloadToMetaTrackEvent };
