import Cookies from "js-cookie";

import { StorageStrategy } from "../abstracts/StorageStrategy";

class CookieStorageStrategy extends StorageStrategy {
  public get<U>(key: string, format: "v1" | "v2" = "v2"): U {
    const data: string = Cookies.get(key);
    if (format === "v1") {
      return this._formatDataOnGetWithOldEncoding(data);
    }

    return this.formatDataOnGet<U>(data);
  }

  public has(key: string): boolean {
    return !!Cookies.get(key)?.length;
  }

  public remove(key: string): CookieStorageStrategy {
    Cookies.remove(key);

    return this;
  }

  public set<U>(key: string, data: U): CookieStorageStrategy {
    Cookies.set(key, this.formatDataOnSet<U>(data), { expires: 365 * 100 });

    return this;
  }
}

export { CookieStorageStrategy };
