enum QueryParameter {
  COUPON = "coupon",
  GCLID = "gclid", // google ads
  GBRAID = "gbraid", // google ads, web to app
  WBRAID = "wbraid", // google ads, app to web
  GCLSRC = "gclsrc", // google ads 360
  DCLID = "dclid", // google display ads
  GAD_SOURCE = "gad_source", // google ads
  TWCLID = "twclid", // twitter
  MSCLKID = "msclkid", // microsoft
  IGSHID = "igshid", // instagram
  FBCLID = "fbclid", // facebook
  RDT_CID = "rdt_cid", // reddit
  LI_FAT_ID = "li_fat_id", // linkedin
  IRCLICKID = "irclickid", // impact
  SCCID = "sccid", // snapchat
  TTCLID = "ttclid", // tiktok
  UTM_CAMPAIGN = "utm_campaign",
  UTM_CONTENT = "utm_content",
  UTM_ID = "utm_id",
  UTM_MEDIUM = "utm_medium",
  UTM_SOURCE = "utm_source",
  UTM_TERM = "utm_term",
  UTM_TEST = "utm_test",
}

export { QueryParameter };
/*
*
  GBRAID = "gbraid", // google ads, web to app
  WBRAID = "wbraid", // google ads, app to web
  GCLSRC = "gclsrc", // google ads 360
  TWCLID = "twclid", // twitter
  IGSHID = "igshid", // instagram
  LI_FAT_ID = "li_fat_id", // linkedin
*
*  */
