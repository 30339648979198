import type { StorageService } from "../Storage";

import type { QueryParameter } from "~/services/demographics/types";

import { getFromQuery } from "~/utils/parsers/getFromQuery";

const saveCoupon = (storageService: typeof StorageService): void => {
  const coupon: string = getFromQuery("coupon" as QueryParameter.COUPON);
  if (coupon.length) {
    storageService.set("coupon" as QueryParameter.COUPON, coupon);
  }
};

export { saveCoupon };
