import isNil from "lodash/isNil";
import stubTrue from "lodash/stubTrue";
import once from "lodash/once";

import type { isValidPhoneNumber } from "libphonenumber-js/min";

import { isStringOrNumber } from "~/utils/helpers/isStringOrNumber";
import { removeAllButNumbers } from "~/utils/converters/removeAllButNumbers";

let _phoneNumberValidator: typeof isValidPhoneNumber;

const loadLibrary = async (): Promise<typeof isValidPhoneNumber> => {
  if (!_phoneNumberValidator) {
    _phoneNumberValidator = (await import(/* webpackChunkName: "libphonenumber-js" */ "libphonenumber-js/min"))
      .isValidPhoneNumber;
  }

  return _phoneNumberValidator;
};

const loadLibraryOnce = once(loadLibrary);

const phoneNumberValidator = async (): Promise<typeof isValidPhoneNumber> => {
  return _phoneNumberValidator || (await loadLibrary()) || stubTrue;
};

const validatePhoneNumber = async (phoneNumber: string | number): Promise<boolean> => {
  if (isNil(phoneNumber) || !isStringOrNumber(phoneNumber)) {
    return false;
  }

  const cleanNumber: string = removeAllButNumbers(phoneNumber);
  if (cleanNumber.length !== 11) {
    if (cleanNumber.length > 1) {
      loadLibraryOnce();
    }

    return false;
  }

  const validate = await phoneNumberValidator();
  return validate(cleanNumber, "US");
};

export { validatePhoneNumber };
