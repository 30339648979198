// Best source i have found: https://bigcommerce.websiteadvantage.com.au/tag-rocket/articles/microsoft-bing-uet-events/

// Enum for Standard UET events from its pixel code
// search in pixel for "knownEvents"
enum UETEventName {
  ADD_PAYMENT_INFO = "add_payment_info",
  ADD_TO_CART = "add_to_cart",
  ADD_TO_WISHLIST = "add_to_wishlist",
  BEGIN_CHECKOUT = "begin_checkout",
  CHECKOUT_PROGRESS = "checkout_progress",
  EXCEPTION = "exception",
  GENERATE_LEAD = "generate_lead",
  LOGIN = "login",
  PAGE_VIEW = "page_view",
  PURCHASE = "purchase",
  REFUND = "refund",
  REMOVE_FROM_CART = "remove_from_cart",
  SCREEN_VIEW = "screen_view",
  SEARCH = "search",
  SELECT_CONTENT = "select_content",
  SET_CHECKOUT_OPTION = "set_checkout_option",
  SHARE = "share",
  SIGN_UP = "sign_up",
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
  VIEW_PROMOTION = "view_promotion",
  VIEW_SEARCH_RESULTS = "view_search_results",
}

export { UETEventName };
