enum AnalyticsEventNames {
  CHECKOUT_SESSION_COMPLETED = "Frontend Checkout Intake Form Session Completed",
  CHECKOUT_SESSION_CREATED = "Frontend Checkout Intake Form Session Created",
  CHECKOUT_SESSION_RESTORED = "Frontend Checkout Intake Form Session Restored",

  SESSION_COMPLETED = "Frontend Intake Form Session Completed",
  SESSION_CREATED = "Frontend Intake Form Session Created",
  SESSION_RESTORED = "Frontend Intake Form Session Restored",

  USER_SIGNED_IN = "Frontend Intake Form User Signed In",
  USER_SIGNED_UP = "Frontend Intake Form User Signed Up",

  CHECKOUT_USER_SIGNED_IN = "Frontend Checkout Intake Form User Signed In",
  CHECKOUT_USER_SIGNED_UP = "Frontend Checkout Intake Form User Signed Up",

  PAYMENT_METHOD_CHANGED = "Intake Form Payment Method View Changed",
  PAYMENT_REQUEST_API_SUPPORTED = "Intake Form Payment Request Supported",
  PAYMENT_REQUEST_BUTTON_CLICKED = "Intake Form Payment Request Button Clicked",

  AUTHORIZATION_PAGE_VIEWED = "Intake Form Authorization Page Viewed",
  FINISH_PAGE_VIEWED = "Intake Form Finish Page Viewed",
}

export { AnalyticsEventNames };
