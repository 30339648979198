import isObjectLike from "lodash/isObjectLike";
import _isArray from "lodash/isArray";

// will throw error in strict mode
const deepSeal = <T>(source: T, excludeArrays: boolean = true, excludeEmptyObjects: boolean = true): void => {
  if (!isObjectLike(source)) {
    return;
  }

  if (!Object.values(source).length && excludeEmptyObjects) {
    return;
  }

  Object.keys(source).forEach((prop) => {
    const isArray: boolean = _isArray((source as any)?.[prop]);
    if (excludeArrays && isArray) {
      (source as any)?.[prop].forEach((value: any) => deepSeal<any>(value));
    } else if (isObjectLike((source as any)?.[prop]) && !isArray) {
      deepSeal<any>((source as any)[prop]);
    }
  });

  Object.seal(source);
};

export { deepSeal };
