import { User } from "../types";

class SentryUserModel implements User {
  public id: string = "";
  public username: string = "";
  public email: string = "";

  public ip_address: string = null;

  public constructor(id: string = null, username: string = null, email: string = null, ipAddress: string = null) {
    if (id?.length) {
      this.id = id;
    }

    if (username?.length) {
      this.username = username;
    }

    if (email?.length) {
      this.email = email;
    }

    if (ipAddress?.length) {
      this.ip_address = ipAddress;
    }
  }
}

export { SentryUserModel };
