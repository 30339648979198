import parseAsString from "lodash/toString";

// we don't care about referrers pointing to our domain
const getReferer = (): string => {
  const originalReferer: string = parseAsString(document?.referrer);
  if (!originalReferer.length || originalReferer.includes("getcurex.com") || originalReferer.includes("localhost")) {
    return "";
  }

  return originalReferer;
};

export { getReferer };
