import { PageModel, RuleModel } from "~/services/redirect/models";
import { RedirectService } from "~/services/redirect/Redirect";

const redirect = () => {
  const { pathname, searchParams, hash }: URL = new URL(window.location.href);
  const query: Record<string, string | string[]> = Object.fromEntries(searchParams);
  const redirectRule: RuleModel | void = RedirectService.findRedirectRule(pathname);
  if (!redirectRule) {
    return;
  }

  const nextPage: PageModel = RedirectService.nextPage(new PageModel({ path: pathname, query, hash }), redirectRule);
  RedirectService.redirectToPage(nextPage);
};

export { redirect };
