import cloneDeep from "lodash/cloneDeep";

import isString from "lodash/isString";
import isNil from "lodash/isNil";

import { InterceptorModel } from "../../models";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";

const jsonRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.JSON_FORMATTER,
  type: InterceptorType.REQUEST,
  callback: <T>({ request }: InterceptorPayload<T>): InterceptorPayload<T> => {
    const newRequest: RequestInit = cloneDeep(request);

    newRequest.headers = new Headers(newRequest?.headers);
    newRequest.headers.set("Accept", "application/json; charset=UTF-8");
    newRequest.headers.set("Content-Type", "application/json; charset=UTF-8");

    if (!isNil(newRequest.body) && !isString(newRequest.body)) {
      newRequest.body = JSON.stringify(newRequest.body);
    }

    return { request: newRequest };
  },
});

export { jsonRequest };
