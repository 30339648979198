enum SentryScope {
  ANALYTICS = "Analytics",
  API = "API",
  AUTHORIZATION = "Authorization",
  AUTHORIZATION_VALIDATION = "AuthorizationValidation",
  COMPOSABLES = "Composables",
  COUPON = "Coupon",
  DATA_REPOSITORY = "DataRepository",
  DEBOUNCE = "Debounce",
  DEBUG = "Debug",
  DEMOGRAPHICS = "Demographics",
  DEMOGRAPHICS_SECTION_LOAD = "DemographicsSectionLoad",
  FETCH = "Fetch",
  FILE_UPLOAD = "FileUpload",
  FRONTEND_VALIDATION = "FrontendValidation",
  GETLABS = "Getlabs",
  KLAVIYO = "Klaviyo",
  MY_CUREX = "MyCurex",
  OTHER = "Other",
  PAYMENT = "Payment",
  REDIRECT = "Redirect",
  RENDER = "RENDER",
  SECTION_LOAD = "SectionLoad",
  SOCIAL = "Social",
  STORAGE = "Storage",
  STORE = "Store",
  STRIPE = "Stripe",
  UTILS = "Utils",
  VUEX = "Vuex",
  VUEX_DATA_VALIDATOR = "VuexDataValidator",
  VUEX_DEMOGRAPHICS = "VuexDemographics",
}

export { SentryScope };
