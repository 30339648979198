const getColor = (colors: string[], index: number) => parseInt(colors[index], 16);

const expandHex = (shortHex: string) =>
  shortHex.replace(/^#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])$/, (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`);

const convertToRGBA = (hex: string, opacity: number): string => {
  const formattedHex: string = hex?.length === 4 ? expandHex(hex) : hex;

  if (formattedHex.length !== 7) {
    throw new Error("Only six-digit - seven-chars hex colors are allowed.");
  }

  const splitColors = formattedHex.replace("#", "").match(/.{1,2}/g);

  if (splitColors) {
    return `rgba(${getColor(splitColors, 0)}, ${getColor(splitColors, 1)}, ${getColor(splitColors, 2)}, ${opacity})`;
  }

  return "rgba(0, 0, 0, 0)";
};

export { convertToRGBA };
