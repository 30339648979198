import isString from "lodash/isString";
import memoize from "lodash/memoize";

import type { ValuesType } from "utility-types";

import { RedirectsService } from "./Redirects";
import { PageModel, RuleModel } from "./models";

import { LoggerService } from "~/services/logger";
import { isExternalUrl } from "~/utils/helpers/isExternalUrl";

class Redirect {
  private readonly _HOMEPAGE: "/" = "/" as const;

  private _normalizedSources: string[] = [];
  private _memoHasRedirectSource = memoize(this.hasRedirectSource); // will be called on almost all route change

  public constructor() {
    this.init();
  }

  public init(): void {
    this._populateNormalizedSources();
  }

  public isPageEligibleForRedirect(path: string): boolean {
    if (!RedirectsService.size) {
      return false;
    }

    return this._memoHasRedirectSource(path);
  }

  public redirectToPage(page: PageModel): void {
    try {
      const baseUrl: string | undefined = isExternalUrl(page.path) ? undefined : window.location.origin;

      const url: URL = new URL(page.path, baseUrl);
      url.hash = page.hash;

      for (const [key, value] of Object.entries(page.query)) {
        if (isString(value)) {
          url.searchParams.set(key, value);
        } else {
          value.forEach((v: string) => url.searchParams.append(key, v));
        }
      }

      window.location.href = url.href;
    } catch (e) {
      // Possibly server or failed to create new URL()
      LoggerService.error("Failed to redirect.", e, page);
    }
  }

  public nextPage(currentPage: PageModel, rule: RuleModel): PageModel {
    const newPage: PageModel = new PageModel();

    let destination: string = rule.destination;
    if (this.isPathWithTemplate(rule.destination)) {
      for (let i = 0; i < rule.sources.length; i++) {
        const source: string = rule.sources[i];
        const currentSource: string = this.getStartOfThePath(source);

        if (currentPage.path.includes(currentSource)) {
          destination = rule.destination.replace(
            `/${RedirectsService.TEMPLATE}`,
            currentPage.path.replace(currentSource, ""),
          );
          break;
        }
      }
    }

    newPage.hash = currentPage.hash;
    newPage.path = destination;
    newPage.query = currentPage.query;

    rule.interceptors.forEach((interceptor: ValuesType<RuleModel["interceptors"]>) => {
      Object.assign(newPage, interceptor(currentPage, newPage));
    });

    return newPage;
  }

  public findRedirectRule(path: string): RuleModel | void {
    if (!this.isPageEligibleForRedirect(path)) {
      return;
    }

    const iterator: IterableIterator<RuleModel> = RedirectsService.values();
    let iteratorResult: IteratorResult<RuleModel, RuleModel> = iterator.next();

    while (!iteratorResult.done) {
      const rule: RuleModel = iteratorResult.value;
      if (this.hasPathInSources(rule.sources, path)) {
        iteratorResult = { value: undefined, done: true };
        return rule;
      }

      iteratorResult = iterator.next();
    }
  }

  public isPathWithTemplate(path: string): boolean {
    return path.includes(RedirectsService.TEMPLATE);
  }

  // this helper only useful to config files
  public getStartOfThePath(path: string): string {
    if (path === this._HOMEPAGE) {
      return path;
    }

    // Remove everything after first template
    if (this.isPathWithTemplate(path)) {
      return path.substring(0, path.indexOf(RedirectsService.TEMPLATE) - 1);
    }

    return path.replace(/\/*$/, "");
  }

  protected hasRedirectSource(path: string): boolean {
    return this.hasPathInSources(this._normalizedSources, path);
  }

  protected hasPathInSources(sources: string[], path: string): boolean {
    if (path === this._HOMEPAGE) {
      // Never redirect from homepage
      return false;
    }

    const startOfThePath: string = this.getStartOfThePath(path);

    for (let i = 0; i < sources.length; i++) {
      const startOfTheSource: string = this.getStartOfThePath(sources[i]);
      if (startOfTheSource === startOfThePath) {
        return true;
      }

      if (this.isPathWithTemplate(sources[i]) && startOfThePath.includes(startOfTheSource)) {
        return true;
      }
    }

    return false;
  }

  private _populateNormalizedSources(): void {
    const sources: string[] = [];
    RedirectsService.forEach((redirect: RuleModel) => sources.push(...redirect.sources));
    this._normalizedSources = [...new Set(sources)];
  }
}

const RedirectService: Redirect = new Redirect();

export { RedirectService };
