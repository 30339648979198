import type { EventPayload } from "../../../types";
import type { UETUserData } from "../types";

import { removeAllButNumbers } from "~/utils/converters/removeAllButNumbers";

const eventPayloadToUserData = (eventPayload: EventPayload): UETUserData => {
  const em: string | "" = eventPayload?.traits?.email?.toLowerCase() || "";
  const ph: string | "" = removeAllButNumbers(eventPayload?.traits?.phone) || "";

  if (!em.length && !ph.length) {
    return {};
  }

  return { em, ph };
};

export { eventPayloadToUserData };
