import isNil from "lodash/isNil";
import merge from "lodash/merge";

import { Destination } from "../../abstracts/Destination";
import type { EventPayload } from "../../types";

import { eventPayloadToUETEvent, eventPayloadToUserData, loadPixel } from "./helpers";
import type { UETEvent } from "./types";

class UETDestination extends Destination {
  private _pageLoadFired: boolean = false;

  public constructor(payload?: EventPayload) {
    super({ id: process?.env?.CX_UET_ID ?? "137014702", name: "UET" });

    this.loadIntegration(payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return !!this.id.length && !isNil(window?.uetq) && !isNil(window?.UET);
  }

  public async loadIntegration(payload?: EventPayload): Promise<void> {
    this.enqueue(() => {
      if (!window?.UET) {
        return;
      }

      window.uetq = new window.UET({
        ti: this.id,
        enableAutoSpaTracking: false,
        q: window.uetq,
        cookieFlags: "SameSite=None;Secure",
      });

      const userData: ReturnType<typeof eventPayloadToUserData> = eventPayloadToUserData(payload);
      if (Object.keys(userData)?.length) {
        window.uetq.push("set", { pid: userData });
      }

      if (!this._pageLoadFired) {
        this._pageLoadFired = true;
        window.uetq.push("pageLoad");
      }
    });

    try {
      await loadPixel();
      this.initDestination();
    } catch (error: unknown) {
      this.logError("Failed to load", error);
    }
  }

  public alias(): void {}

  public identify(payload: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to identify", this.id);
        return;
      }

      const userData: ReturnType<typeof eventPayloadToUserData> = eventPayloadToUserData(payload);
      if (!Object.keys(userData)?.length) {
        return;
      }

      window.uetq.push("set", { pid: userData });
    });
  }

  public page(payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track page", this.id);
        return;
      }

      window.uetq.push(
        "event",
        "page_view",
        this._mergePropertiesWithUserData(payload, {
          page_title: payload?.page?.title,
          page_location: payload?.page?.url,
          page_path: payload?.page?.path,
        }),
      );
    });
  }

  public track(event: string, payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track", this.id);
        return;
      }

      eventPayloadToUETEvent(event, payload).forEach((eventData: UETEvent) => {
        window.uetq.push(
          eventData.type,
          eventData.name,
          this._mergePropertiesWithUserData(payload, eventData.properties),
        );
      });
    });
  }

  private _mergePropertiesWithUserData<T>(payload: EventPayload, properties?: T): T {
    const userData: ReturnType<typeof eventPayloadToUserData> = eventPayloadToUserData(payload);
    if (!Object.keys(userData)?.length) {
      return properties;
    }

    return merge({}, properties, { pid: userData });
  }
}

export { UETDestination };
