import type { EventPayload } from "../../../types";

import type { GoogleTagPageData } from "../types";

// https://developers.google.com/analytics/devguides/collection/ga4/reference/config#page_location
const eventPayloadToGoogleTagPageData = (eventPayload?: EventPayload): GoogleTagPageData => {
  return {
    page_location: (eventPayload?.page?.url || "")?.substring(0, 998), // max 1000 chars
    page_referrer: (eventPayload?.page?.referrer || "")?.substring(0, 418), // max 420 chars
    page_title: (eventPayload?.page?.title || "")?.substring(0, 298), // max 300 chars
  };
};

export { eventPayloadToGoogleTagPageData };
