import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";

import type { EventProperties } from "@segment/analytics-core";
import type { EventPayload } from "../../../types";

// https://segment.com/docs/connections/spec/page/#properties
const eventPayloadToPageData = (eventPayload?: EventPayload | void): EventProperties => {
  if (!eventPayload || isNil(eventPayload)) {
    return {} as EventProperties;
  }

  const result: EventProperties = {
    path: eventPayload?.page?.path,
    referrer: eventPayload?.page?.referrer,
    search: eventPayload?.page?.search,
    title: eventPayload?.page?.title,
    url: eventPayload?.page?.url,
  };

  return pickBy(result, (value: string | undefined) => value !== undefined);
};

export { eventPayloadToPageData };
