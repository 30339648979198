import type { EventPayload } from "../../types";

import { getGitVersion } from "~/modules/revision/helpers/getGitVersion";

const getAppContext = (): EventPayload["context"]["app"] => {
  return {
    name: "Webflow",
    version: getGitVersion(),
    build: process.env.BUILD_TIME || "0", // This should be replaced with the actual app build
  };
};

export { getAppContext };
