import { isObject } from "~/utils/validation/isObject";

const isWebAssemblySupported = (): boolean => {
  try {
    if (!isObject(WebAssembly) || typeof WebAssembly.instantiate !== "function") {
      return false;
    }

    const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
    if (module instanceof WebAssembly.Module) {
      return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export { isWebAssemblySupported };
