import type { Required } from "utility-types";

import type { MetaEventProperties } from "./metaEventProperties";
import { MetaEventName } from "./metaEventName";

enum MetaEventType {
  TRACK = "track",
  TRACK_CUSTOM = "trackCustom",
}

interface BaseEvent<N extends string | MetaEventName, T extends MetaEventType> {
  name: N;
  type: T;
  properties: MetaEventProperties;
}

interface AddPaymentInfoEvent extends BaseEvent<MetaEventName.ADD_PAYMENT_INFO, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "content_category" | "contents" | "currency" | "value">;
}

interface AddToCartEvent extends BaseEvent<MetaEventName.ADD_TO_CART, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "content_name" | "content_type" | "contents" | "currency" | "value">;
}

interface AddToWishlistEvent extends BaseEvent<MetaEventName.ADD_TO_WISHLIST, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "content_name" | "content_category" | "contents" | "currency" | "value">;
}

interface CompleteRegistrationEvent extends BaseEvent<MetaEventName.COMPLETE_REGISTRATION, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "content_name" | "currency" | "status" | "value">;
}

interface ContactEvent extends BaseEvent<MetaEventName.CONTACT, MetaEventType.TRACK> {}

interface CustomizeProductEvent extends BaseEvent<MetaEventName.CUSTOMIZE_PRODUCT, MetaEventType.TRACK> {}

interface DonateEvent extends BaseEvent<MetaEventName.DONATE, MetaEventType.TRACK> {}

interface FindLocationEvent extends BaseEvent<MetaEventName.FIND_LOCATION, MetaEventType.TRACK> {}

interface InitiateCheckoutEvent extends BaseEvent<MetaEventName.INITIATE_CHECKOUT, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "content_category" | "contents" | "currency" | "num_items" | "value">;
}

interface LeadEvent extends BaseEvent<MetaEventName.LEAD, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "content_category" | "content_name" | "currency" | "value">;
}

interface PurchaseEvent extends BaseEvent<MetaEventName.PURCHASE, MetaEventType.TRACK> {
  properties: Required<
    MetaEventProperties,
    "content_name" | "content_type" | "contents" | "currency" | "num_items" | "value"
  > & { order_id?: string };
  // i'm not sure that order_id is supported, there are mentions in the code and in some forum threads but not in the official doc.
}

interface ScheduleEvent extends BaseEvent<MetaEventName.SCHEDULE, MetaEventType.TRACK> {}

interface SearchEvent extends BaseEvent<MetaEventName.SEARCH, MetaEventType.TRACK> {
  properties: Required<
    MetaEventProperties,
    "content_category" | "content_type" | "contents" | "currency" | "search_string" | "value"
  >;
}

interface StartTrialEvent extends BaseEvent<MetaEventName.START_TRIAL, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "currency" | "predicted_ltv" | "value">;
}

interface SubmitApplicationEvent extends BaseEvent<MetaEventName.SUBMIT_APPLICATION, MetaEventType.TRACK> {}

interface SubscribeEvent extends BaseEvent<MetaEventName.SUBSCRIBE, MetaEventType.TRACK> {
  properties: Required<MetaEventProperties, "currency" | "predicted_ltv" | "value">;
}

interface ViewContentEvent extends BaseEvent<MetaEventName.VIEW_CONTENT, MetaEventType.TRACK> {
  properties: Required<
    MetaEventProperties,
    "content_category" | "content_name" | "content_type" | "contents" | "currency" | "value"
  >;
}

interface CustomEvent extends BaseEvent<string, MetaEventType.TRACK_CUSTOM> {}

// Union Type for All Events
type MetaEvent =
  | AddPaymentInfoEvent
  | AddToCartEvent
  | AddToWishlistEvent
  | CompleteRegistrationEvent
  | ContactEvent
  | CustomizeProductEvent
  | DonateEvent
  | FindLocationEvent
  | InitiateCheckoutEvent
  | LeadEvent
  | PurchaseEvent
  | ScheduleEvent
  | SearchEvent
  | StartTrialEvent
  | SubmitApplicationEvent
  | SubscribeEvent
  | ViewContentEvent
  | CustomEvent;

export type {
  AddPaymentInfoEvent,
  AddToCartEvent,
  AddToWishlistEvent,
  CompleteRegistrationEvent,
  ContactEvent,
  CustomizeProductEvent,
  DonateEvent,
  FindLocationEvent,
  InitiateCheckoutEvent,
  LeadEvent,
  PurchaseEvent,
  ScheduleEvent,
  SearchEvent,
  StartTrialEvent,
  SubmitApplicationEvent,
  SubscribeEvent,
  ViewContentEvent,
  CustomEvent,
  MetaEvent,
};

export { MetaEventType };
