import isNil from "lodash/isNil";

import { isStringOrNumber } from "~/utils/helpers/isStringOrNumber";

const validatePostalCode = (postalCode: string | number): boolean => {
  if (isNil(postalCode) || !isStringOrNumber(postalCode)) {
    return false;
  }

  const re = /^\d{5}(-\d{4})?$/;
  return !!re?.test(String(postalCode));
};

export { validatePostalCode };
