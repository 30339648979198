import merge from "lodash/merge";

class ErrorInfoModel {
  public code: number = -1;
  public detail: string = "";
  public message: string[] = [];
  public extra: Record<string, string> = {};

  public constructor(source: Partial<ErrorInfoModel> = {}) {
    merge(this, source);
  }
}

export { ErrorInfoModel };
