import { Vue } from "nuxt-property-decorator";

import isNil from "lodash/isNil";

import { ErrorInfoModel, ErrorModel, InterceptorModel } from "../../models";
import { ErrorType, InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";
import { redirectResponseError } from "../../errors";

import { isJsonString } from "~/utils/tests/isJsonString";
import { AuthorizationRepositoryService } from "~/services/AuthorizationRepository";

const redirectResponse: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.REDIRECT_ON_AUTH_FAIL,
  type: InterceptorType.RESPONSE,
  callback: <T>({ request, response }: InterceptorPayload<T>): InterceptorPayload<T> => {
    if (isNil(response) || response.status !== 401) {
      return { request, response };
    }

    AuthorizationRepositoryService.module.reset();

    Vue.prototype.router.redirect({
      name: "login",
      query: { redirect: Vue.prototype.route.fullPath },
    });

    const requestData: object = isJsonString(request?.body) ? JSON.parse(request.body) : {};
    const data: ErrorInfoModel = new ErrorInfoModel({ code: 1003, detail: "Request declined" });
    throw redirectResponseError(new ErrorModel({ type: ErrorType.FRONTEND, data }), requestData);
  },
});

export { redirectResponse };
