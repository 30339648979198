import type { EventPayload } from "../../../types";

import type { GoogleTagUserProperties } from "../types";

// https://developers.google.com/analytics/devguides/collection/protocol/ga4/user-properties?client_type=gtag
// https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_properties

/*
  * Restrictions:
  Some user property names are reserved and cannot be used in measurements:
  * first_open_time
  * first_visit_time
  * last_deep_link_referrer
  * user_id
  * first_open_after_install
  Additionally, user property names cannot begin with:
  * google_
  * ga_
  * firebase_
  *  */

// User properties are custom defined, and can be used somewhere in GA4 dashboard
const eventPayloadToGoogleTagUserProperties = (eventPayload: EventPayload): GoogleTagUserProperties => {
  const result: GoogleTagUserProperties = {};

  const age: number | void = eventPayload?.traits?.age || undefined;
  if (age) {
    result.age = age.toString();
  }

  const gender: string | void = eventPayload?.traits?.gender || undefined;
  if (gender?.length) {
    result.gender = gender;
  }

  const channel: string | void = eventPayload?.context?.channel || undefined;
  if (channel?.length) {
    result.channel = channel;
  }

  const appName: string | void = eventPayload?.context?.app?.name || undefined;
  if (appName?.length) {
    result.app_name = appName;
  }

  const appVersion: string | void = eventPayload?.context?.app?.version || undefined;
  if (appVersion?.length) {
    result.app_version = appVersion;
  }

  const deviceType: string | void = eventPayload?.context?.device?.type || undefined;
  if (deviceType?.length) {
    result.device_type = deviceType;
  }

  const deviceVendor: string | void = eventPayload?.context?.device?.vendor || undefined;
  if (deviceVendor?.length) {
    result.device_vendor = deviceVendor;
  }

  const deviceModel: string | void = eventPayload?.context?.device?.model || undefined;
  if (deviceModel?.length) {
    result.device_model = deviceModel;
  }

  const deviceArchitecture: string | void = eventPayload?.context?.device?.architecture || undefined;
  if (deviceArchitecture?.length) {
    result.device_architecture = deviceArchitecture;
  }

  const deviceOsName: string | void = eventPayload?.context?.device?.os?.name || undefined;
  if (deviceOsName?.length) {
    result.device_os_name = deviceOsName;
  }

  const deviceOsVersion: string | void = eventPayload?.context?.device?.os?.version || undefined;
  if (deviceOsVersion?.length) {
    result.device_os_version = deviceOsVersion;
  }

  const deviceLanguage: string | void = eventPayload?.context?.device?.language || undefined;
  if (deviceLanguage?.length) {
    result.device_language = deviceLanguage;
  }

  const deviceTimezone: string | void = eventPayload?.context?.device?.timezone || undefined;
  if (deviceTimezone?.length) {
    result.device_timezone = deviceTimezone;
  }

  const deviceNetworkType: string | void = eventPayload?.context?.device?.network?.type || undefined;
  if (deviceNetworkType?.length) {
    result.device_network_type = deviceNetworkType;
  }

  const deviceNetworkEffectiveType: string | void = eventPayload?.context?.device?.network?.effectiveType || undefined;
  if (deviceNetworkEffectiveType?.length) {
    result.device_network_effective_type = deviceNetworkEffectiveType;
  }

  return result;
};

export { eventPayloadToGoogleTagUserProperties };
