import { UserModel } from "~/services/ApiUser/models";
import { RedditService } from "~/services/reddit";
import { TikTokService } from "~/services/tiktok";

const updateAnalyticsWithUserData = (user: UserModel): void => {
  const phone: string =
    user?.patient?.phone?.length && user?.patient?.phone !== "not provided" ? user?.patient?.phone : "";

  TikTokService.identify({ external_id: user.id, email: user.email, phone_number: phone });
  RedditService.init({ externalId: user.id, email: user.email });
};

export { updateAnalyticsWithUserData };
