import type { EventPayload } from "../../types";

const getDeviceScreenContext = (): EventPayload["context"]["device"]["screen"] => {
  return {
    devicePixelRatio: window?.devicePixelRatio || -1,
    colorDepth: window?.screen?.colorDepth || -1,
    pixelDepth: window?.screen?.pixelDepth || -1,

    height: window?.screen?.height || -1,
    width: window?.screen?.width || -1,

    isExtended: !!(window?.screen as { isExtended?: boolean })?.isExtended,
    orientation: window?.screen?.orientation?.type || "portrait-primary",

    avail: {
      height: window?.screen?.availHeight || -1,
      width: window?.screen?.availWidth || -1,
    },

    viewport: {
      height: window?.innerHeight || -1,
      width: window?.innerWidth || -1,
    },
  };
};

export { getDeviceScreenContext };
