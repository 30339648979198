import { StorageStrategy } from "./abstracts/StorageStrategy";

import { LocalStorageStrategy } from "./strategies/LocalStorageStrategy";
import { CookieStorageStrategy } from "./strategies/CookieStorageStrategy";
import { SessionStorageStrategy } from "./strategies/SessionStorageStrategy";
import { MemoryStorageStrategy } from "./strategies/MemoryStorageStrategy";

import { Mode } from "./types";

import { isCookiesStorageSupported } from "~/utils/tests/isCookiesStorageSupported";
import { isLocalStorageSupported } from "~/utils/tests/isLocalStorageSupported";
import { isSessionStorageSupported } from "~/utils/tests/isSessionStorageSupported";

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";
import { SentryService } from "~/services/sentry/SentryService";

// todo: caching is not ideal, as one of the tab might change and another might have things in cache

class Storage {
  protected _writeMode: Mode = Mode.CACHE;
  protected _storage: StorageStrategy;
  private _cache: MemoryStorageStrategy = new MemoryStorageStrategy();

  public constructor() {
    this.init();
  }

  public init(): void {
    this._determineWriteMode();
    this._migrateOldFormatClients();
  }

  public set<U>(key: string, data: U): Storage {
    // this._cache.set<U>(key, data);
    this._storage.set<U>(key, data);

    return this;
  }

  /**
   * If key:value is not present in any storage void will be returned
   * @template U
   * @returns {(U | void)}
   */
  public get<U>(key: string): U | void {
    /*     if (this._cache.has(key)) {
          return this._cache.get<U>(key);
        } */

    try {
      // this._cache.set<U>(key, data);

      return this._storage.get<U>(key);
    } catch (error) {
      SentryService.report(
        new SentryError()
          .addExtra("key", key)
          .addFingerprint("Storage.get")
          .addTag(SentryTagKey.METHOD, SentryScope.STORAGE)
          .setError(error)
          .setMessage("Failed to get data from Storage")
          .setName("Storage"),
      );
    }
  }

  public remove(key: string): Storage {
    // this._cache.remove(key);
    this._storage.remove(key);

    return this;
  }

  public has(key: string): boolean {
    return /* this._cache.has(key) ||  */ this._storage.has(key);
  }

  protected _determineWriteMode(): void {
    if (isLocalStorageSupported()) {
      this._writeMode = Mode.LOCAL_STORAGE;
      this._storage = new LocalStorageStrategy();
      return;
    }

    if (isCookiesStorageSupported()) {
      this._writeMode = Mode.COOKIE;
      this._storage = new CookieStorageStrategy();
      return;
    }

    if (isSessionStorageSupported()) {
      this._writeMode = Mode.SESSION_STORAGE;
      this._storage = new SessionStorageStrategy();
      return;
    }

    this._writeMode = Mode.CACHE;
    this._storage = this._cache;
  }

  // todo: should this be deleted at any time in the future?
  protected _migrateOldFormatClients(): void {
    if (this.has("format") && this.get("format") === "v2") {
      return;
    }

    [
      "coupon",
      "authorization",
      "referral",
      "visitUtmModels",
      "all_traits",
      "anonymous_id",
      "authentication_order",
      "context",
      "cookie-consented",
    ].forEach((key: string) => {
      if (this.has(key)) {
        const value = this._storage?.get(key, "v1");
        if (value) {
          this._storage?.set(key, value);
        }
      }
    });

    this._storage?.set("format", "v2");
  }
}

const StorageService: Storage = new Storage();

export { StorageService };
