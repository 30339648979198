const isSessionStorageSupported = (): boolean => {
  const testKey = "__storage_test__";

  try {
    sessionStorage.setItem(testKey, testKey);
    sessionStorage.removeItem(testKey);

    return true;
  } catch (error) {
    return false;
  }
};

export { isSessionStorageSupported };
