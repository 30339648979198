const isInputDateSupported = (): boolean => {
  let result = false;

  try {
    const input = document.createElement("input");
    const value = "a";

    input.setAttribute("type", "date");
    input.setAttribute("value", value);

    result = input.value !== value;
  } catch (error) {
    console.error(error);
  }

  return result;
};

export { isInputDateSupported };
