import isNil from "lodash/isNil";
import isObjectLike from "lodash/isObjectLike";
import merge from "lodash/merge";

import parseAsString from "lodash/toString";
import {
  appInfoRequest,
  authorizationRequest,
  errorResponse,
  jsonRequest,
  jsonResponse,
  noCacheRequest,
  queryInfoRequest,
  redirectResponse,
  timeoutRequest,
} from "./interceptors";
import { InterceptorModel, RequestConfigModel } from "./models";
import { InterceptorPayload } from "./types";
import { parseResponseAsString } from "./helpers";
import { SentryService } from "~/services/sentry/SentryService";
import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

class Fetch {
  public requestInterceptors: InterceptorModel[] = [
    jsonRequest,
    noCacheRequest,
    authorizationRequest,
    appInfoRequest,
    queryInfoRequest,
    timeoutRequest,
  ];

  public responseInterceptors: InterceptorModel[] = [jsonResponse, redirectResponse, errorResponse];

  public static async parseJson<T>(response: Response, config: RequestConfigModel<T>): Promise<T> {
    if (config.allowEmptyResponse && !(await parseResponseAsString(response)).length) {
      return {} as T;
    }

    const responseJson: T = await response.json();
    if (isObjectLike(config.model) && isObjectLike(responseJson)) {
      return merge(config.model, responseJson);
    }

    return responseJson;
  }

  public static attachDataToUrl(url: string, data?: Record<string, any>): string {
    if (isNil(data)) {
      return url;
    }

    const searchParams: URLSearchParams = new URLSearchParams();
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      searchParams.set(key, value);
    });

    return `${url}?${searchParams}`;
  }

  public async deleteJson<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<T> {
    return await Fetch.parseJson<T>(await this.fetch(url, { method: "DELETE", body: data }, config), config);
  }

  public async delete<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<Response> {
    return await this.fetch(url, { method: "DELETE", body: data }, config);
  }

  public async patchJson<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<T> {
    return await Fetch.parseJson<T>(await this.fetch(url, { method: "PATCH", body: data }, config), config);
  }

  public async patch<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<Response> {
    return await this.fetch(url, { method: "PATCH", body: data }, config);
  }

  public async putJson<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<T> {
    return await Fetch.parseJson<T>(await this.fetch(url, { method: "PUT", body: data }, config), config);
  }

  public async put<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<Response> {
    return await this.fetch(url, { method: "PUT", body: data }, config);
  }

  public async getJson<T>(
    url: string,
    data?: Record<string, any>,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<T> {
    const urlWithParams: string = Fetch.attachDataToUrl(url, data);
    return await Fetch.parseJson<T>(await this.fetch(urlWithParams, { method: "GET" }, config), config);
  }

  public async get<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<Response> {
    const urlWithParams: string = Fetch.attachDataToUrl(url, data);
    return await this.fetch(urlWithParams, { method: "GET" }, config);
  }

  public async postJson<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<T> {
    return await Fetch.parseJson<T>(await this.fetch(url, { method: "POST", body: data }, config), config);
  }

  public async post<T>(
    url: string,
    data?: any,
    config: RequestConfigModel<T> = new RequestConfigModel(),
  ): Promise<Response> {
    return await this.fetch(url, { method: "POST", body: data }, config);
  }

  protected async fetch<T>(
    url: string,
    initialRequestConfig: RequestInit,
    config: RequestConfigModel<T>,
  ): Promise<Response> {
    const { request: modifiedByInterceptorsRequestConfig }: InterceptorPayload<T> = await this.executeInterceptors(
      this.requestInterceptors,
      {
        request: initialRequestConfig,
        config,
      },
    );

    const modifiedRequestConfig: RequestInit = merge({}, modifiedByInterceptorsRequestConfig, config.request);
    if (config.request?.headers instanceof Headers) {
      modifiedRequestConfig.headers = new Headers(modifiedByInterceptorsRequestConfig?.headers);
      config.request?.headers.forEach((value, key) => {
        (modifiedRequestConfig.headers as Headers).set(key, value);
      });
    }

    const response: Response = await fetch(url, modifiedRequestConfig);

    // Modifying response could be unsafe and some data can be lost.
    await this.executeInterceptors<T>(this.responseInterceptors, { request: modifiedRequestConfig, response, config });

    return response;
  }

  protected async executeInterceptors<T>(
    interceptors: InterceptorModel[],
    { request, response, config }: InterceptorPayload<T>,
  ): Promise<InterceptorPayload<T>> {
    let currentInterceptor: string = "";
    try {
      const filteredInterceptors: InterceptorModel[] = interceptors.filter(
        (interceptor: InterceptorModel) => !config.exclude.includes(interceptor.category),
      );

      const interceptorArguments: InterceptorPayload<T> = {
        request,
        response,
        config,
      };

      for (const interceptor of filteredInterceptors) {
        currentInterceptor = interceptor.category;
        const result: InterceptorPayload<T> = await interceptor.callback<T>(interceptorArguments);
        if (result?.request) {
          interceptorArguments.request = result.request;
        }
        if (result?.response) {
          interceptorArguments.response = result.response;
        }
      }

      return interceptorArguments;
    } catch (error) {
      SentryService.report(
        new SentryError()
          .setName("Error In Fetch Interceptors")
          .setMessage("Error")
          .setError(error)
          .addExtra("CurrentInterceptor", currentInterceptor)
          .addExtra("InterceptorsCategory", interceptors.map((interceptor) => interceptor.category).join(","))
          .addExtra("InterceptorsType", interceptors.map((interceptor) => interceptor.type).join(","))
          // @ts-expect-error its non documented feature that is supported widely
          .addExtra("Stack", parseAsString(error?.stack))
          .addTag(SentryTagKey.SCOPE, SentryScope.AUTHORIZATION)
          .addFingerprint("FetchService.executeInterceptors"),
      );
      throw error;
    }
  }
}

const FetchService: Fetch = new Fetch();

export { FetchService, Fetch };
