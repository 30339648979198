enum Color {
  BEIGE_100 = "beige-100",
  BLUE_100 = "blue-100",
  BLUE_150 = "blue-150",
  BLUE_200 = "blue-200",
  BLUE_300 = "blue-300",
  BLUE_400 = "blue-400",
  BLUE_500 = "blue-500",
  PEACH_50 = "peach-50",
  PINK_100 = "pink-100",
  PINK_200 = "pink-200",
  PINK_300 = "pink-300",
  PINK_400 = "pink-400",
  YELLOW_100 = "yellow-100",
  YELLOW_200 = "yellow-200",
  YELLOW_300 = "yellow-300",
  YELLOW_500 = "yellow-500",
  GREEN_100 = "green-100",
  GREEN_200 = "green-200",
  GREEN_300 = "green-300",
  GREEN_400 = "green-400",
  GREEN_500 = "green-500",
  BLACK = "black",
  GRAY_50 = "gray-50",
  GRAY_75 = "gray-75",
  GRAY_100 = "gray-100",
  GRAY_200 = "gray-200",
  GRAY_300 = "gray-300",
  GRAY_500 = "gray-500",
  GRAY_600 = "gray-600",
  GRAY_700 = "gray-700",
  BROWN_200 = "brown-200",
  WHITE = "white",
  TRANSPARENT = "transparent",
}

export { Color };
