import merge from "lodash/merge";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";

import type { EventPayload } from "./types";
import { ALL_TRAITS, ANONYMOUS_ID, AUTHENTICATION_ODER, CONTEXT } from "./constants";

import type { Destination } from "./abstracts/Destination";

import { GoogleTagDestination } from "./destinations/googleTag";
import { MetaDestination } from "./destinations/meta";
import { UETDestination } from "./destinations/uet";
import { VirtualDestination } from "./destinations/virtual";
import { SegmentDestination } from "./destinations/segment";
import { PostHogDestination } from "./destinations/posthog";
import { ClaritasDestination } from "./destinations/claritas";

import { getSegmentAnonymousId, saveAnonymousIdToCookies } from "./helpers";

import { StorageService } from "~/services/storage/Storage";

import type { PromiseFunction } from "~/services/task/types";
import { TaskQueue } from "~/services/task/TaskQueue";

import { syncFunctionToAsyncFunction } from "~/utils/converters/syncFunctionToAsyncFunction";
import { isPromise } from "~/utils/validation/isPromise";
import { uuidv4 } from "~/utils/helpers/uuidv4";

// todo: add session id feature

// todo: add a feature that tells if every event is send

class Analytics<T = { [key: string]: any }> {
  private _environment: "production" | "staging" | "development" =
    (process.env.CX_ENVIRONMENT as "development") || "development";

  private _destinations: Destination[] = [];

  private _tasksQueue: TaskQueue = new TaskQueue();
  private _page: EventPayload["page"] = {};

  public constructor() {
    this._initStorage();

    const lastAuthenticatedUserId: EventPayload["userId"] = this.authenticationOder?.at?.(-1);
    if (lastAuthenticatedUserId?.length) {
      this._currentUserId = lastAuthenticatedUserId;
    }

    saveAnonymousIdToCookies(this.anonymousId);
  }

  private _currentUserId: EventPayload["userId"] = "";

  public get currentUserId(): EventPayload["userId"] | void {
    return this._currentUserId || undefined;
  }

  public get isIdentified(): boolean {
    return !!this.currentUserId && !!this.currentUserId?.length;
  }

  public get authenticationOder(): EventPayload["userId"][] {
    return (StorageService.get<EventPayload["userId"][]>(AUTHENTICATION_ODER) || []) as EventPayload["userId"][];
  }

  /**
   * Get all stored traits
   */
  public get allTraits(): Record<EventPayload["userId"], EventPayload["traits"]> {
    return StorageService.get<Record<EventPayload["userId"], EventPayload["traits"]>>(ALL_TRAITS) as Record<
      EventPayload["userId"],
      EventPayload["traits"]
    >;
  }

  // todo: is get working properly on our service?
  public get anonymousId(): EventPayload["anonymousId"] {
    return (StorageService.get<EventPayload["anonymousId"]>(ANONYMOUS_ID) || uuidv4()) as EventPayload["anonymousId"];
  }

  public get currentPage(): EventPayload["page"] {
    return this._page;
  }

  /**
   * Get current execution context
   */
  public get context(): EventPayload["context"] {
    return (StorageService.get<EventPayload["context"]>(CONTEXT) || {}) as EventPayload["context"];
  }

  /**
   * Returns current authenticated user, or the last user to be authenticated
   * Empty object if no traits found
   */
  public get traits(): Partial<EventPayload["traits"]> {
    const currentUserTraits: EventPayload["traits"] = this.allTraits?.[this._currentUserId];
    if (currentUserTraits?.id?.length) {
      return currentUserTraits;
    }

    const lastUserTraits: EventPayload["traits"] = this.allTraits?.[this.authenticationOder?.at?.(-1)];
    if (lastUserTraits?.id?.length) {
      return lastUserTraits;
    }

    return {};
  }

  /**
   * Init analytics, call this once
   */
  public initDestinations(): void {
    const eventPayload: EventPayload = this._createEventPayload();

    if (this._environment !== "production" && !StorageService.has("debug-analytics")) {
      this.logWarning(`Disabled. No 'debug-analytics' flag supplied, environment is ${this._environment}`);
      this._destinations = [new VirtualDestination(eventPayload)];
      return;
    }

    this._destinations = [
      new GoogleTagDestination(eventPayload),
      new MetaDestination(eventPayload),
      new UETDestination(eventPayload),
      new PostHogDestination(eventPayload),
      new ClaritasDestination(eventPayload),
    ];

    if (this._environment !== "production") {
      this._destinations.push(new VirtualDestination(eventPayload));
    }

    // As per agreement, we are reducing MTU usage of segment
    if (eventPayload?.userId?.length) {
      this._destinations.push(new SegmentDestination(eventPayload));
    }

    setTimeout(() => this.healthCheck(5), 5000);
  }

  public healthCheck(seconds: number): void {
    const notReadyDestinations: Destination["name"][] = this._destinations
      .filter((destination) => !destination.ready)
      .map((destination) => destination.name);

    if (!notReadyDestinations.length) {
      return;
    }

    this.logWarning(`Destinations not ready in ${seconds} seconds:`, notReadyDestinations, this);
  }

  public setCurrentUserId(userId: EventPayload["userId"]): void {
    this._currentUserId = userId;
    if (!userId.length) {
      return;
    }

    const newData: EventPayload["userId"][] = uniq([...this.authenticationOder, userId].reverse()).reverse();
    if (isEqual(newData, this.authenticationOder)) {
      return;
    }

    StorageService.set<EventPayload["userId"][]>(AUTHENTICATION_ODER, newData);
  }

  /**
   * Update traits when any new information is added about the user
   */
  public updateTraits(newTraits: EventPayload["traits"]): void {
    const userId: EventPayload["traits"]["id"] = newTraits?.id || "";
    if (!userId?.length && !this._currentUserId.length) {
      this.logWarning(`Traits User ID is empty:`, userId);
      return;
    }

    if (userId?.length) {
      this.setCurrentUserId(userId);
    }

    if (userId?.length && !this._destinations.find((destination) => destination.name === "Segment")) {
      this._destinations.push(new SegmentDestination(this._createEventPayload()));
    }

    const newData: Record<EventPayload["userId"], EventPayload["traits"]> = merge({}, this.allTraits, {
      [userId || this._currentUserId]: newTraits,
    });

    if (isEqual(newData, this.allTraits)) {
      return;
    }

    StorageService.set<Record<EventPayload["userId"], EventPayload["traits"]>>(ALL_TRAITS, newData);
  }

  /**
   * Update current page
   * Note: Query string is removed from auth page
   */
  public updatePage(newPage: EventPayload["page"] = {}): void {
    const formattedNewPage: EventPayload["page"] = merge({}, this._page, newPage);
    if (formattedNewPage?.path?.includes("/login")) {
      formattedNewPage.path = "/login";
      formattedNewPage.search = "";
    }

    if (formattedNewPage?.url?.includes("/login")) {
      formattedNewPage.url = formattedNewPage?.url?.split?.("?")?.[0] || formattedNewPage?.url;
    }

    if (formattedNewPage?.referrer?.includes("/login")) {
      formattedNewPage.referrer = formattedNewPage?.referrer?.split("?")?.[0] || formattedNewPage?.referrer;
    }

    // remove stripe page from referer, because it can interfere with conversion events
    if (formattedNewPage?.referrer?.includes("stripe.com")) {
      formattedNewPage.referrer = formattedNewPage.url;
    }

    // backend link shortener with auth token
    if (formattedNewPage?.referrer?.includes("/s?")) {
      formattedNewPage.referrer = "/s";
    }

    this._page = formattedNewPage;
  }

  /**
   * Update analytics context, when anything about user/device/page... changes
   */
  public updateContext(newContext: EventPayload["context"] = {}): void {
    const newData: EventPayload["context"] = merge({}, this.context, newContext);
    if (isEqual(newData, this.context)) {
      return;
    }

    StorageService.set<EventPayload["context"]>(CONTEXT, newData);
  }

  public identify(properties?: T): void {
    this._enqueue(() => {
      const eventPayload: EventPayload = this._createEventPayload(properties);
      this._destinations.forEach((destination: Destination) => destination.identify(eventPayload));
    });
  }

  public track(event: string, properties?: T): void {
    this._enqueue(() => {
      const eventPayload: EventPayload = this._createEventPayload(properties);
      this._destinations.forEach((destination: Destination) => destination.track(event, eventPayload));
    });
  }

  public page(properties?: T): void {
    this._enqueue(() => {
      const eventPayload: EventPayload = this._createEventPayload(properties);
      this._destinations.forEach((destination: Destination) => destination.page(eventPayload));
    });
  }

  public alias(userId: string, previousId?: string, properties?: T): void {
    this._enqueue(() => {
      const eventPayload: EventPayload = this._createEventPayload(properties);
      this._destinations.forEach((destination: Destination) => destination.alias(userId, previousId, eventPayload));
    });
  }

  protected logWarning(message: string, ...args: unknown[]): void {
    console.warn(`[Analytics] ${message}.`, ...(args || []));
  }

  private _initStorage(): void {
    if (!StorageService.has(ANONYMOUS_ID)) {
      // todo: remove segment anonymous id in the next year
      StorageService.set<EventPayload["anonymousId"]>(ANONYMOUS_ID, getSegmentAnonymousId() || uuidv4());
    }

    if (!StorageService.has(CONTEXT)) {
      StorageService.set<EventPayload["context"]>(CONTEXT, {});
    }

    if (!StorageService.has(ALL_TRAITS)) {
      StorageService.set<Record<EventPayload["userId"], EventPayload["traits"]>>(ALL_TRAITS, {});
    }

    if (!StorageService.has(AUTHENTICATION_ODER)) {
      StorageService.set<EventPayload["userId"][]>(ALL_TRAITS, []);
    }
  }

  private _enqueue<T, A extends any[]>(callback: (...args: any[]) => T, args?: A): void {
    if (isPromise(callback)) {
      this._tasksQueue.enqueue(callback as PromiseFunction<T, A>, args);
      return;
    }

    this._tasksQueue.enqueue(syncFunctionToAsyncFunction(callback), args);
  }

  private _createEventPayload(properties: T = {} as T): EventPayload {
    return {
      anonymousId: this.anonymousId,
      userId: this.currentUserId as EventPayload["userId"],

      messageId: uuidv4(),
      sentAt: new Date().toISOString(),

      page: this.currentPage,
      traits: this.traits as EventPayload["traits"],

      context: this.context,
      properties,
    };
  }
}

const AnalyticsService: Analytics = new Analytics();

export { AnalyticsService };
