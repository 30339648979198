import parseAsString from "lodash/toString";

const getTikTokPixelCookie = (): string => {
  try {
    const regex: RegExp = /ttclid=(?<ttclid>\S*\b)/gm;
    const match: RegExpMatchArray = regex.exec(document.cookie);

    return parseAsString(match?.groups?.ttclid);
  } catch (error) {
    return "";
  }
};

export { getTikTokPixelCookie };
