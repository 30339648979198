import cloneDeep from "lodash/cloneDeep";
import parseAsString from "lodash/toString";
import { InterceptorModel } from "~/services/fetch/models";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "~/services/fetch/types";
import { StorageService } from "~/services/storage/Storage";

const authorizationRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.AUTHORIZATION,
  type: InterceptorType.REQUEST,
  callback: <T>({ request }: InterceptorPayload<T>): InterceptorPayload<T> => {
    if (!StorageService.has("authorization")) {
      return { request };
    }

    const authorization: { token: string; lastUpdated: number } | void = StorageService.get("authorization");
    if (!authorization) {
      return { request };
    }

    const newRequest: RequestInit = cloneDeep(request);

    newRequest.headers = new Headers(newRequest?.headers);
    newRequest.headers.set("Authorization", `JWT ${authorization.token}`);
    newRequest.headers.set("Authorization-Token-Changed", parseAsString(authorization.lastUpdated));

    return { request: newRequest };
  },
});

export { authorizationRequest };
