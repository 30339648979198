import { StorageStrategy } from "../abstracts/StorageStrategy";

class MemoryStorageStrategy extends StorageStrategy {
  private _cache: Map<string, any> = new Map();

  public get<U>(key: string): U {
    return this._cache.get(key);
  }

  public has(key: string): boolean {
    return this._cache.has(key);
  }

  public remove(key: string): MemoryStorageStrategy {
    this._cache.delete(key);

    return this;
  }

  public set<U>(key: string, data: U): MemoryStorageStrategy {
    this._cache.set(key, data);

    return this;
  }
}

export { MemoryStorageStrategy };
