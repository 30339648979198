import isObjectLike from "lodash/isObjectLike";
import isArray from "lodash/isArray";

import { stringifyValue } from "./stringifyValue";

import { isJsonString } from "~/utils";

import { Extras } from "~/services/sentry/types";

const parseDataAsSentryExtras = (data: any = null): Extras => {
  const extras: Map<string, string> = new Map();

  if (isObjectLike(data)) {
    if (isArray(data)) {
      data.forEach((value: any, index: number) => {
        extras.set(`Item-${index}`, stringifyValue(value));
      });
    } else if (data instanceof Object) {
      Object.entries(data).forEach(([key, value]: [string, unknown]) => {
        if (!`${key}`.includes("password")) {
          extras.set(`Item-${key}`, stringifyValue(value));
        }
      });
    } else {
      const value: string = isJsonString(data) ? stringifyValue(data) : data?.toString?.() || "";
      extras.set(`Couldn't parse data on so called valid object`, value);
    }
  }

  return Object.fromEntries(extras);
};

export { parseDataAsSentryExtras };
