import { RenderContext } from "vue";

import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";
import isString from "lodash/isString";

import { LoggerService } from "~/services/logger";

const getClassesFromContext = (context: RenderContext): string[] => {
  if (isNil(context?.data?.class)) {
    return [];
  }

  if (isArray(context.data.class)) {
    return context.data.class;
  }

  if (isObject(context.data.class)) {
    const parentClasses: string[] = [];
    Object.entries(context.data.class).forEach(([key, value]) => {
      if (value) {
        parentClasses.push(key);
      }
    });

    return parentClasses;
  }

  if (isString(context.data.class) || isNumber(context.data.class)) {
    return [`${context.data.class}`];
  }

  LoggerService.error("getClassesFromContext: Class is not applied,", context.data.class);

  return [];
};

export { getClassesFromContext };
