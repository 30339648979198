import type { Required } from "utility-types";

import type { UETEventProperties } from "./UETEventProperties";
import type { UETEventName } from "./UETEventName";

enum UETEventType {
  PAGE_LOAD = "pageLoad",
  SET = "set",
  CONSENT = "consent",
  EVENT = "event",
}

interface BaseEvent<N extends string | UETEventName, T extends UETEventType> {
  name: N;
  type: T;
  properties: UETEventProperties;
}

interface AddPaymentInfoEvent extends BaseEvent<UETEventName.ADD_PAYMENT_INFO, UETEventType.EVENT> {}

interface AddToCartEvent extends BaseEvent<UETEventName.ADD_TO_CART, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "items" | "revenue_value" | "currency">;
}

interface AddToWishlistEvent extends BaseEvent<UETEventName.ADD_TO_WISHLIST, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "items" | "revenue_value" | "currency">;
}

interface BeginCheckoutEvent extends BaseEvent<UETEventName.BEGIN_CHECKOUT, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "items" | "coupon" | "revenue_value" | "currency">;
}

interface CheckoutProgressEvent extends BaseEvent<UETEventName.CHECKOUT_PROGRESS, UETEventType.EVENT> {
  properties: Required<
    UETEventProperties,
    "items" | "coupon" | "checkout_step" | "checkout_option" | "revenue_value" | "currency"
  >;
}

interface ExceptionEvent extends BaseEvent<UETEventName.EXCEPTION, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "description" | "fatal">;
}

interface GenerateLeadEvent extends BaseEvent<UETEventName.GENERATE_LEAD, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "transaction_id" | "revenue_value" | "currency">;
}

interface LoginEvent extends BaseEvent<UETEventName.LOGIN, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "method">;
}

interface PageViewEvent extends BaseEvent<UETEventName.PAGE_VIEW, UETEventType.EVENT> {
  properties: Required<
    UETEventProperties,
    "page_title" | "page_location" | "page_path" | "rep" | "tpp" | "gtm_tag_source"
  >;
}

interface PurchaseEvent extends BaseEvent<UETEventName.PURCHASE, UETEventType.EVENT> {
  properties: Required<
    UETEventProperties,
    "transaction_id" | "tax" | "shipping" | "items" | "coupon" | "revenue_value" | "currency"
  >;
}

interface RefundEvent extends BaseEvent<UETEventName.REFUND, UETEventType.EVENT> {
  properties: Required<
    UETEventProperties,
    "transaction_id" | "tax" | "shipping" | "items" | "revenue_value" | "currency"
  >;
}

interface RemoveFromCartEvent extends BaseEvent<UETEventName.REMOVE_FROM_CART, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "items" | "revenue_value" | "currency">;
}

interface ScreenViewEvent extends BaseEvent<UETEventName.SCREEN_VIEW, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "screen_name">;
}

interface SearchEvent extends BaseEvent<UETEventName.SEARCH, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "search_term">;
}

interface SelectContentEvent extends BaseEvent<UETEventName.SELECT_CONTENT, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "items" | "promotions" | "content_type" | "content_id">;
}

interface SetCheckoutOptionEvent extends BaseEvent<UETEventName.SET_CHECKOUT_OPTION, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "checkout_step" | "checkout_option">;
}

interface ShareEvent extends BaseEvent<UETEventName.SHARE, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "method" | "content_type" | "content_id">;
}

interface SignUpEvent extends BaseEvent<UETEventName.SIGN_UP, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "method">;
}

interface ViewItemEvent extends BaseEvent<UETEventName.VIEW_ITEM, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "items">;
}

interface ViewItemListEvent extends BaseEvent<UETEventName.VIEW_ITEM_LIST, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "items">;
}

interface ViewPromotionEvent extends BaseEvent<UETEventName.VIEW_PROMOTION, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "promotions">;
}

interface ViewSearchResultsEvent extends BaseEvent<UETEventName.VIEW_SEARCH_RESULTS, UETEventType.EVENT> {
  properties: Required<UETEventProperties, "search_term">;
}

// custom event can be an empty string or any string
interface CustomEvent extends BaseEvent<string, UETEventType.EVENT> {}

// Union Type for All Events
type UETEvent =
  | AddPaymentInfoEvent
  | AddToCartEvent
  | AddToWishlistEvent
  | BeginCheckoutEvent
  | CheckoutProgressEvent
  | ExceptionEvent
  | GenerateLeadEvent
  | LoginEvent
  | PageViewEvent
  | PurchaseEvent
  | RefundEvent
  | RemoveFromCartEvent
  | ScreenViewEvent
  | SearchEvent
  | SelectContentEvent
  | SetCheckoutOptionEvent
  | ShareEvent
  | SignUpEvent
  | ViewItemEvent
  | ViewItemListEvent
  | ViewPromotionEvent
  | ViewSearchResultsEvent
  | CustomEvent;

export type {
  AddPaymentInfoEvent,
  AddToCartEvent,
  AddToWishlistEvent,
  BeginCheckoutEvent,
  CheckoutProgressEvent,
  ExceptionEvent,
  GenerateLeadEvent,
  LoginEvent,
  PageViewEvent,
  PurchaseEvent,
  RefundEvent,
  RemoveFromCartEvent,
  ScreenViewEvent,
  SearchEvent,
  SelectContentEvent,
  SetCheckoutOptionEvent,
  ShareEvent,
  SignUpEvent,
  ViewItemEvent,
  ViewItemListEvent,
  ViewPromotionEvent,
  ViewSearchResultsEvent,
  UETEvent,
};

export { UETEventType };
