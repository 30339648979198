import cloneDeep from "lodash/cloneDeep";

import { InterceptorModel } from "../../models";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";

import { QueryStorageService } from "~/services/QueryStorage/QueryStorage";

const queryInfoRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.QUERY_INFO,
  type: InterceptorType.REQUEST,
  callback: <T>({ request }: InterceptorPayload<T>): InterceptorPayload<T> => {
    const newRequest: RequestInit = cloneDeep(request);

    newRequest.headers = new Headers(newRequest?.headers);
    if (QueryStorageService.visitModelsShort.length) {
      newRequest.headers.set("Visit-Models", JSON.stringify(QueryStorageService.visitModelsShort));
    }

    return { request: newRequest };
  },
});

export { queryInfoRequest };
