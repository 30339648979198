import isNil from "lodash/isNil";
import isEqual from "lodash/isEqual";

import { Destination } from "../../abstracts/Destination";
import type { EventPayload } from "../../types";

import {
  eventPayloadToGoogleTagCampaignData,
  eventPayloadToGoogleTagEvents,
  eventPayloadToGoogleTagPageData,
  eventPayloadToGoogleTagUserData,
  eventPayloadToGoogleTagUserProperties,
  loadPixel,
} from "./helpers";

import type {
  GoogleTagCampaignData,
  GoogleTagEvent,
  GoogleTagPageData,
  GoogleTagUserData,
  GoogleTagUserProperties,
} from "./types";

class GoogleTagDestination extends Destination {
  protected _clientId: string = "";
  protected _userId: string = "";
  protected _campaignData: GoogleTagCampaignData = eventPayloadToGoogleTagCampaignData();
  protected _pageData: GoogleTagPageData = eventPayloadToGoogleTagPageData();
  protected _userData: GoogleTagUserData = {};
  protected _userProperties: GoogleTagUserProperties = {};

  public constructor(payload?: EventPayload) {
    super({ id: process?.env?.CX_GOOGLE_TAG_ID ?? "GTM-NVDCTHG", name: "GoogleTag" });

    this.loadIntegration(payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return !!this.id.length && !isNil(window?.dataLayer) && !isNil(window?.gtag);
  }

  // gtag need to have all the user props set before finishing initialization, to fire all the events in the correct order
  public async loadIntegration(payload?: EventPayload): Promise<void> {
    this.enqueue(() => {
      this._updateIdData(payload);
      this._updateCampaignData(payload);
      this._updateUserData(payload);
      this._updateUserProperties(payload);
    });

    try {
      await loadPixel(this.id);
      this.initDestination();
    } catch (error: unknown) {
      this.logError("Failed to load", error);
    }
  }

  public alias(): void {}

  public identify(payload: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to identify", this.id);
      return;
    }

    this.enqueue(() => {
      this._updateIdData(payload);
      this._updateCampaignData(payload);
      this._updateUserData(payload);
      this._updateUserProperties(payload);
    });
  }

  public page(payload?: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to track page", this.id);
      return;
    }

    this.enqueue(() => {
      this._updatePageData(payload);

      gtag("config", this.id, {
        ...this._pageData,
        send_page_view: false,
        update: true,
      });

      window.gtag("event", "page_view", this._pageData);
    });
  }

  public track(event: string, payload?: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to track", this.id);
      return;
    }

    this.enqueue(() => {
      eventPayloadToGoogleTagEvents(event, payload).forEach((eventData: GoogleTagEvent) =>
        window.gtag("event", eventData.name, eventData?.properties || {}),
      );
    });
  }

  private _updateIdData(payload?: EventPayload): void {
    if (payload?.anonymousId?.length && payload?.anonymousId !== this._clientId) {
      this._clientId = payload.anonymousId;
      window.gtag("set", "client_id", this._clientId);
    }

    if (payload?.userId?.length && payload?.userId !== this._userId) {
      this._userId = payload.userId;
      window.gtag("set", "user_id", this._userId);
    }
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#campaign
  private _updateCampaignData(payload?: EventPayload): void {
    const campaignData: GoogleTagCampaignData = eventPayloadToGoogleTagCampaignData(payload);
    if (isEqual(this._campaignData, campaignData)) {
      return;
    }

    this._campaignData = campaignData;
    window.gtag("set", "campaign_id", this._campaignData.id);
    window.gtag("set", "campaign_source", this._campaignData.source);
    window.gtag("set", "campaign_medium", this._campaignData.medium);
    window.gtag("set", "campaign_name", this._campaignData.name);
    window.gtag("set", "campaign_term", this._campaignData.term);
    window.gtag("set", "campaign_content", this._campaignData.content);
  }

  private _updateUserData(payload?: EventPayload): void {
    const userData: GoogleTagUserData = eventPayloadToGoogleTagUserData(payload);
    if (isEqual(this._userData, userData)) {
      return;
    }

    this._userData = userData;
    window.gtag("set", "user_data", this._userData);
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_properties
  private _updateUserProperties(payload?: EventPayload): void {
    const userProperties: ReturnType<typeof eventPayloadToGoogleTagUserProperties> =
      eventPayloadToGoogleTagUserProperties(payload);

    if (isEqual(this._userProperties, userProperties)) {
      return;
    }

    this._userProperties = userProperties;
    window.gtag("set", "user_properties", this._userProperties);
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#page_location
  private _updatePageData(payload?: EventPayload): void {
    const pageData: ReturnType<typeof eventPayloadToGoogleTagPageData> = eventPayloadToGoogleTagPageData(payload);

    if (isEqual(this._pageData, pageData)) {
      return;
    }

    this._pageData = pageData;
    window.gtag("set", "page_location", this._pageData.page_location);
    window.gtag("set", "page_referrer", this._pageData.page_referrer);
    window.gtag("set", "page_title", this._pageData.page_title);
  }
}

export { GoogleTagDestination };
