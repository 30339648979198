import cloneDeep from "lodash/cloneDeep";

import { InterceptorModel } from "../../models";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";

const timeoutRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.TIMEOUT,
  type: InterceptorType.REQUEST,
  callback: <T>({ request, config }: InterceptorPayload<T>): InterceptorPayload<T> => {
    const newRequest: RequestInit = cloneDeep(request);

    if (config.controller) {
      setTimeout(() => {
        if (!config.controller.signal.aborted) {
          config.controller.abort();
        }
      }, config.timeout * 1000);
    }

    newRequest.signal = config.controller.signal;

    return { request: newRequest };
  },
});

export { timeoutRequest };
