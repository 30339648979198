import { isObject } from "../validation/isObject";

const lowerCaseObjectKeys = <T>(source: T): T => {
  if (!isObject(source)) {
    return source;
  }

  return Object.fromEntries(Object.entries(source).map(([k, v]) => [k.toLowerCase().trim(), v])) as T;
};

export { lowerCaseObjectKeys };
