import isFunction from "lodash/isFunction";
import isArray from "lodash/isArray";

import type { RestArgs } from "./types";
import { generateString, isPromise } from "~/utils";
import { SentryError } from "~/services/sentry/SentryError";
import { LoggerService } from "~/services/logger";

/** Class representing a single task. */
class Task<
  TaskContext = any,
  TaskArguments = any[],
  TaskReturnValue = void,
  TaskFunction = (...args: RestArgs<TaskArguments>) => TaskReturnValue,
> {
  protected _arguments: TaskArguments;
  protected _context: TaskContext = null;
  protected _functionReference: TaskFunction;
  protected _isExecuting: boolean = false;
  protected _suppressErrors: boolean = true;
  protected _ttl: number = 0;
  protected _fingerprint: string = generateString(4);

  /**
   * Create new task.
   * @param {function} functionReference - Function to execute. Can be a promise, arrow function or just a function.
   */
  public constructor(functionReference: any) {
    this._functionReference = functionReference;
  }

  /**
   * @return {boolean} Returns true if task execution is in progress.
   */
  public get isExecuting(): boolean {
    return this._isExecuting;
  }

  /**
   * @return {boolean} Returns unique fingerprint of a task.
   */
  public get fingerprint(): string {
    return this._fingerprint;
  }

  /**
   * @param {array} args - Array of task function arguments.
   * @return {Task} instance.
   */
  public setArguments(args: TaskArguments): Task<TaskContext, TaskArguments, TaskReturnValue, TaskFunction> {
    this._arguments = args;
    return this;
  }

  /**
   * @param {number=0} value - Time in _ms_ to not wait for a function result. Default is infinite.
   * @return {Task} instance.
   */
  public setTtl(value: number = 0): Task<TaskContext, TaskArguments, TaskReturnValue, TaskFunction> {
    this._ttl = value;
    return this;
  }

  /**
   * @param {object} context - Set function execution context. Useful if it's not an arrow function.
   * @return {Task} instance.
   */
  public setContext(context: TaskContext): Task<TaskContext, TaskArguments, TaskReturnValue, TaskFunction> {
    this._context = context;
    return this;
  }

  /**
   * @param {boolean=true} value - Set if task will suppress any errors.
   * @return {Task} instance.
   */
  public suppressErrors(value: boolean = true): Task<TaskContext, TaskArguments, TaskReturnValue, TaskFunction> {
    this._suppressErrors = value;
    return this;
  }

  /**
   * Calls the task with context and arguments set previously.
   * Returns a promise containing task result or calls callback with result if set.
   * @param {function=} callback - Call callback with task result if set.
   * @return {Promise<any>} Task result.
   */
  public async execute(callback?: (result?: TaskReturnValue | void) => void): Promise<TaskReturnValue | void> {
    if (this._isExecuting) {
      return;
    }

    const shouldCallCallback: boolean = isFunction(callback);
    this._isExecuting = true;

    let result: TaskReturnValue | void;
    try {
      result = await this._execute();
    } catch (error) {
      if (error instanceof SentryError) {
        // circular error, dont throw and report sentry errors
        LoggerService.error("Task error: ", error);
        return;
      }

      const taskExecuteErrorModule = await import(/* webpackMode: "lazy" */ "./reportSentryErrors");
      taskExecuteErrorModule.taskExecuteError(error, this._ttl);
      this._errorHook(error);
    } finally {
      this._isExecuting = false;
    }

    if (shouldCallCallback) {
      callback(result);
      return;
    }

    return result;
  }

  protected callFunction<T>(): T {
    if (!isFunction(this._functionReference)) {
      return;
    }

    if (isArray(this._arguments) && !!this._arguments.length) {
      return this._functionReference.apply(this._context, this._arguments);
    }

    return this._functionReference();
  }

  protected _execute(): Promise<TaskReturnValue | void> {
    if (!this._ttl) {
      return this._createTaskPromise();
    }

    return Promise.race([this._createTaskPromise(), this._createTimeoutPromise()]);
  }

  protected _createTaskPromise(): Promise<TaskReturnValue> {
    if (isPromise(this._functionReference)) {
      return this.callFunction<Promise<TaskReturnValue>>();
    }

    return new Promise((resolve) => {
      resolve(this.callFunction<TaskReturnValue>());
    });
  }

  protected _createTimeoutPromise(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, this._ttl);
    });
  }

  protected _errorHook(error?: unknown | Error) {
    if (!this._suppressErrors) {
      throw error;
    }
  }
}

export { Task };
