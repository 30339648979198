import cloneDeep from "lodash/cloneDeep";

import { Destination } from "../../abstracts/Destination";

import type { EventPayload } from "../../types";

class VirtualDestination extends Destination {
  public constructor(payload?: EventPayload) {
    super({ name: "VirtualDestination" });

    this.loadIntegration(payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return true;
  }

  public loadIntegration(payload?: EventPayload): void {
    this.logInfo("initDestination", payload);
    this.initDestination();
  }

  public alias(userId: string, previousId?: string, payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to alias");
        return;
      }

      this.logInfo("alias", userId, previousId, payload);
    });
  }

  public identify(payload: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to identify");
        return;
      }

      this.logInfo("identify", payload);
    });
  }

  public page(payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track page");
        return;
      }

      this.logInfo("page", payload);
    });
  }

  public track(event: string, payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track");
        return;
      }

      this.logInfo("track", event, payload);
    });
  }

  protected logInfo(message: string, ...args: unknown[]): void {
    console.info(`[${this.name}] ${message} called:`, ...(cloneDeep(args) || []));
  }
}

export { VirtualDestination };
