import cloneDeep from "lodash/cloneDeep";

import { InterceptorModel } from "../../models";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";

const noCacheRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.NO_CACHE,
  type: InterceptorType.REQUEST,
  callback: <T>({ request }: InterceptorPayload<T>): InterceptorPayload<T> => {
    const newRequest: RequestInit = cloneDeep(request);

    newRequest.cache = "no-cache";

    return { request: newRequest };
  },
});

export { noCacheRequest };
