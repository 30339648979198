const defaultAttributes: Record<string, string> = { async: "async", importance: "low" };

// Don't defer non-important scripts because it will increase loading times of our website
const loadScript = (
  src: string,
  callback: () => Promise<void> | void = () => {},
  attributes?: Record<string, string>,
): void => {
  try {
    const mergedAttributes: Record<string, string> = { ...defaultAttributes, ...(attributes || {}) };
    const script = document.createElement("script");

    script.setAttribute("src", src);
    Object.entries(mergedAttributes).forEach(([key, value]) => script.setAttribute(key, value));
    script.addEventListener("load", callback);

    document.body.appendChild(script);
  } catch (error) {
    console.error(error);
  }
};

export { loadScript };
