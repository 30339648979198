import cloneDeep from "lodash/cloneDeep";
import parseAsString from "lodash/toString";

import { InterceptorModel } from "../../models";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";

import { AuthorizationRepositoryService } from "~/services/AuthorizationRepository";

const authorizationRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.AUTHORIZATION,
  type: InterceptorType.REQUEST,
  callback: <T>({ request }: InterceptorPayload<T>): InterceptorPayload<T> => {
    if (!AuthorizationRepositoryService.module.isTokenSet) {
      return { request };
    }

    const newRequest: RequestInit = cloneDeep(request);

    newRequest.headers = new Headers(newRequest?.headers);
    newRequest.headers.set("Authorization", `JWT ${AuthorizationRepositoryService.module.token}`);
    newRequest.headers.set(
      "Authorization-Token-Changed",
      parseAsString(AuthorizationRepositoryService.module.lastUpdated),
    );
    newRequest.headers.set(
      "Authorization-Token-Needs-Renewal",
      parseAsString(AuthorizationRepositoryService.module.isOutdated),
    );

    return { request: newRequest };
  },
});

export { authorizationRequest };
