const debouncePromiseWithCancelAndCache = <T, A extends Array<unknown>>(
  fn: (...args: A) => Promise<T>,
  ms: number = 0
) => {
  const cache: Map<string, T> = new Map();
  let lastTimer: ReturnType<typeof setTimeout> | undefined;
  let cancelled = false;

  const debounced = (...args: A) => {
    const key: string = JSON.stringify(args);
    if (cache.has(key)) {
      cancelled = true;
      return cache.get(key);
    }

    cancelled = false;
    if (lastTimer) {
      clearTimeout(lastTimer);
    }
    return new Promise<T>((resolve, reject) => {
      const curTimer = setTimeout(async () => {
        try {
          let res;
          if (!cancelled && curTimer === lastTimer) {
            res = await fn(...args);
          }
          if (!cancelled && curTimer === lastTimer) {
            cache.set(JSON.stringify(args), res);
            resolve(res);
          }
        } catch (e) {
          reject(e);
        }
      }, ms);
      lastTimer = curTimer;
    });
  };

  debounced.cancel = function () {
    cancelled = true;
  };

  return debounced;
};

export { debouncePromiseWithCancelAndCache };
