import merge from "lodash/merge";
import { ErrorType } from "../types";
import { ErrorInfoModel } from "./ErrorInfoModel";

class ErrorModel {
  public status: "error" | "success" = "error";
  public data: ErrorInfoModel = new ErrorInfoModel();
  public type: ErrorType = ErrorType.FRONTEND;

  public constructor(source: Partial<ErrorModel> = {}) {
    merge(this, source);
  }
}

export { ErrorModel };
