const stylesToString = (_styles: Partial<CSSStyleDeclaration>): string => {
  return Object.keys(_styles).reduce(
    (acc, key) =>
      acc +
      key
        .split(/(?=[A-Z])/)
        .join("-")
        .toLowerCase() +
      ":" +
      // @ts-ignore
      _styles[key] +
      ";",
    ""
  );
};

export { stylesToString };
