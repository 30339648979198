import { StorageService } from "~/services/storage/Storage";
import { TokenModel } from "~/services/ApiAuthorization/models";
import { ApiAuthorizationService } from "~/services/ApiAuthorization";
import { LoggerService } from "~/services/logger";

const TWELVE_HOURS: number = 4.32e7;

const getNewToken = async (sourceToken: string = ""): Promise<string> => {
  try {
    const { token }: TokenModel = await ApiAuthorizationService.refreshToken(new TokenModel(sourceToken));
    return token;
  } catch (error) {
    LoggerService.error("Failed to refresh token", error);
  }

  return "";
};

const isOutdated = (lastUpdated: number): boolean => {
  return lastUpdated + TWELVE_HOURS <= new Date().getTime();
};

const authentication = async (onSuccess: Function, onFail: Function = () => {}): Promise<void> => {
  if (!StorageService.has("authorization")) {
    await onFail?.();
    return;
  }

  const authorization: { token: string; lastUpdated: number } | void = StorageService.get("authorization");
  if (!authorization || !authorization.token.length) {
    await onFail?.();
    return;
  }

  if (isOutdated(authorization.lastUpdated)) {
    const newToken: string = await getNewToken(authorization.token);
    if (newToken) {
      StorageService.set("authorization", { token: newToken, lastUpdated: new Date().getTime() });
    }
  }

  await onSuccess?.();
};

export { authentication };
