import isNil from "lodash/isNil";

import { Destination } from "../../abstracts/Destination";

import type { EventPayload } from "../../types";
import type { ClaritasParameters } from "./types/claritasParameters";

import { eventPayloadToClaritasTrackEvent } from "./helpers/eventPayloadToClaritasTrackEvent";

class ClaritasDestination extends Destination {
  private _baseUrl: "https://trkn.us/pixel/c" = "https://trkn.us/pixel/c" as const;

  public constructor(payload?: EventPayload) {
    super({
      id: process?.env?.CX_CLARITAS_ID ?? "24181",
      name: "ClaritasDestination",
    });

    this.loadIntegration(payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return true;
  }

  public static attachDataToUrl(url: string, data?: Record<string, any>): string {
    if (isNil(data)) {
      return url;
    }

    const searchParams: URLSearchParams = new URLSearchParams();
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      searchParams.set(key, value);
    });

    return `${url}?${searchParams}`;
  }

  public loadIntegration(_payload?: EventPayload): void {
    this.initDestination();
  }

  public alias(_userId: string, _previousId?: string, _payload?: EventPayload): void {}

  public identify(_payload: EventPayload): void {}

  public page(payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track page");
        return;
      }

      this.sendRequest(eventPayloadToClaritasTrackEvent(this.id, "Page View", payload));
    });
  }

  public track(event: string, payload?: EventPayload): void {
    this.enqueue(() => {
      if (!this.isDestinationInstanceReady) {
        this.logError("Failed to track");
        return;
      }

      const parameters: ClaritasParameters = eventPayloadToClaritasTrackEvent(this.id, event, payload);
      if (!parameters?.g?.length) {
        return;
      }

      this.sendRequest(parameters);
    });
  }

  protected sendRequest(parameters: ClaritasParameters): void {
    window?.navigator?.sendBeacon?.(ClaritasDestination.attachDataToUrl(this._baseUrl, parameters));
  }
}

export { ClaritasDestination };
