import UAParser from "ua-parser-js";

import type { EventPayload } from "../../types";

import { getDeviceScreenContext } from "./getDeviceScreenContext";
import { getDeviceNetworkContext } from "./getDeviceNetworkContext";
import { cleanObject } from "~/utils/filters/cleanObject";

const getDeviceContext = (): EventPayload["context"]["device"] => {
  const { browser, cpu, device, os, engine, ua }: ReturnType<typeof UAParser> = UAParser();

  return cleanObject({
    userAgent: ua,
    timezone: window?.Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone || "America/New_York",

    cookieEnabled: !!navigator?.cookieEnabled,
    deviceMemory: (navigator as unknown as { deviceMemory?: number })?.deviceMemory || -1,

    language: navigator?.language || "en-US",
    languages: navigator?.languages?.length ? (navigator.languages as string[]) : ["en-US"],

    type: device?.type, // console, mobile, tablet, smarttv, wearable, embedded
    vendor: device?.vendor, // Acer, Alcatel, Amazon, Apple...
    model: device?.model, // determined dynamically
    architecture: cpu?.architecture, // 68k, amd64, arm[64/hf], avr, ia[32/64]...

    browser: cleanObject({
      name: browser?.name, // Android Browser, Avast, Chrome WebView, Chrome, Chromium, DuckDuckGo, Edge, Facebook,...
      version: browser?.version, // determined dynamically
    }),

    engine: cleanObject({
      name: engine?.name, // Amaya, Blink, EdgeHTML, Flow, Gecko, WebKit...
      version: engine?.version, // determined dynamically
    }),

    os: cleanObject({
      name: os?.name, // AIX, Amiga OS, Android[-x86], Arch, Ubuntu, Unix...
      version: os?.version, // determined dynamically
    }),

    screen: getDeviceScreenContext(),
    network: getDeviceNetworkContext(),
  });
};

export { getDeviceContext };
