import { ErrorName } from "../types";
import { ErrorModel } from "../models";
import { parseDataAsSentryExtras } from "../helpers";
import { fetchInterceptorErrorOptions } from "../defaults";

import { SentryError } from "~/services/sentry/SentryError";
import { Extras, SentryScope, SentryTagKey } from "~/services/sentry/types";

const errorResponseError = (error: ErrorModel, data?: any): SentryError => {
  const sentryError: SentryError = new SentryError()
    .setName(ErrorName.BACKEND_ERROR)
    .setMessage("Received error from backend.")
    .setError(new Error(ErrorName.BACKEND_ERROR, fetchInterceptorErrorOptions))
    .setResponse(error)
    .addTag(SentryTagKey.SCOPE, SentryScope.FETCH)
    .addFingerprint("Fetch.errorResponseError");

  const extras: Extras = parseDataAsSentryExtras(data);

  for (const key in extras) {
    sentryError.addExtra(key, extras[key] as any);
  }

  return sentryError;
};

export { errorResponseError };
