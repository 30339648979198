import { getModule } from "nuxt-property-decorator";
import { Repository } from "~/services/Repository";
import AuthStore from "~/store/modules/auth";

export class AuthorizationRepository extends Repository<AuthStore> {
  public get module(): AuthStore {
    return getModule<AuthStore>(AuthStore, this.store);
  }
}

export const AuthorizationRepositoryService: AuthorizationRepository = new AuthorizationRepository();
