import parseAsString from "lodash/toString";

import { UtmModel } from "../models";
import { QueryParameter } from "../types";
import { isUtmModelEmpty } from "./isUtmModelEmpty";

import { getFromQuery } from "~/utils";

const createUtmModelFromQueryParameters = (): UtmModel | void => {
  if (!window?.location?.href?.includes("?") && !document?.referrer?.includes("?")) {
    // nothing in the query
    return;
  }

  const utmModelFromUrl: UtmModel = new UtmModel({
    coupon: getFromQuery(QueryParameter.COUPON),

    // click id
    gclid: getFromQuery(QueryParameter.GCLID),
    gbraid: getFromQuery(QueryParameter.GBRAID),
    wbraid: getFromQuery(QueryParameter.WBRAID),
    gclsrc: getFromQuery(QueryParameter.GCLSRC),
    dclid: getFromQuery(QueryParameter.DCLID),
    gad_source: getFromQuery(QueryParameter.GAD_SOURCE),
    twclid: getFromQuery(QueryParameter.TWCLID),
    msclkid: getFromQuery(QueryParameter.MSCLKID),
    igshid: getFromQuery(QueryParameter.IGSHID),
    fbclid: getFromQuery(QueryParameter.FBCLID),
    rdt_cid: getFromQuery(QueryParameter.RDT_CID),
    li_fat_id: getFromQuery(QueryParameter.LI_FAT_ID),
    irclickid: getFromQuery(QueryParameter.IRCLICKID),
    sccid: getFromQuery(QueryParameter.SCCID),
    ttclid: getFromQuery(QueryParameter.TTCLID),

    // utm
    utm_campaign: getFromQuery(QueryParameter.UTM_CAMPAIGN),
    utm_content: getFromQuery(QueryParameter.UTM_CONTENT),
    utm_id: getFromQuery(QueryParameter.UTM_ID),
    utm_medium: getFromQuery(QueryParameter.UTM_MEDIUM),
    utm_source: getFromQuery(QueryParameter.UTM_SOURCE),
    utm_term: getFromQuery(QueryParameter.UTM_TERM),
    utm_test: getFromQuery(QueryParameter.UTM_TEST),
  });

  if (!isUtmModelEmpty(utmModelFromUrl)) {
    return utmModelFromUrl;
  }

  const referrer: string = parseAsString(document?.referrer);
  const referrerValidAndHasSearchString: boolean =
    referrer.includes("getcurex.com?") || referrer.includes("getcurex.com/?");

  if (!referrerValidAndHasSearchString) {
    return;
  }

  const searchString: string = parseAsString(referrer.split("?")?.[1]);
  const utmModelFromReferrer: UtmModel = new UtmModel({
    coupon: getFromQuery(QueryParameter.COUPON, searchString),

    // click id
    gclid: getFromQuery(QueryParameter.GCLID, searchString),
    gbraid: getFromQuery(QueryParameter.GBRAID, searchString),
    wbraid: getFromQuery(QueryParameter.WBRAID, searchString),
    gclsrc: getFromQuery(QueryParameter.GCLSRC, searchString),
    dclid: getFromQuery(QueryParameter.DCLID, searchString),
    gad_source: getFromQuery(QueryParameter.GAD_SOURCE, searchString),
    twclid: getFromQuery(QueryParameter.TWCLID, searchString),
    msclkid: getFromQuery(QueryParameter.MSCLKID, searchString),
    igshid: getFromQuery(QueryParameter.IGSHID, searchString),
    fbclid: getFromQuery(QueryParameter.FBCLID, searchString),
    rdt_cid: getFromQuery(QueryParameter.RDT_CID, searchString),
    li_fat_id: getFromQuery(QueryParameter.LI_FAT_ID, searchString),
    irclickid: getFromQuery(QueryParameter.IRCLICKID, searchString),
    sccid: getFromQuery(QueryParameter.SCCID, searchString),
    ttclid: getFromQuery(QueryParameter.TTCLID, searchString),

    // utm
    utm_campaign: getFromQuery(QueryParameter.UTM_CAMPAIGN, searchString),
    utm_content: getFromQuery(QueryParameter.UTM_CONTENT, searchString),
    utm_id: getFromQuery(QueryParameter.UTM_ID, searchString),
    utm_medium: getFromQuery(QueryParameter.UTM_MEDIUM, searchString),
    utm_source: getFromQuery(QueryParameter.UTM_SOURCE, searchString),
    utm_term: getFromQuery(QueryParameter.UTM_TERM, searchString),
    utm_test: getFromQuery(QueryParameter.UTM_TEST, searchString),
  });

  if (!isUtmModelEmpty(utmModelFromReferrer)) {
    return utmModelFromReferrer;
  }
};

export { createUtmModelFromQueryParameters };
