import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import pickBy from "lodash/pickBy";
import { UtmModel } from "./UtmModel";

class VisitModel {
  public path?: string; // page that client first visited our website
  public referer?: string; // page that client was referenced by, like google.com
  public createdAt?: number;
  public data?: UtmModel; // utm model

  public constructor(source: Partial<VisitModel> = {}) {
    merge(this, cloneDeep(pickBy(source, Boolean)));

    if (this.data) {
      this.data = new UtmModel(this.data);
    }
  }
}

export { VisitModel };
