import isNil from "lodash/isNil";
import isString from "lodash/isString";

import { StatesService } from "~/services/states";

const validateState = (state: string): boolean => {
  if (isNil(state) || !isString(state)) {
    return false;
  }

  return !!StatesService.findState(state);
};

export { validateState };
