/* eslint-disable */
// @ts-nocheck

import { loadScript } from "~/utils/helpers/loadScript";

declare global {
  interface Window {
    fbq: any;
  }
}

const loadPixel = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      if (window.fbq) {
        resolve();
        return;
      }

      window.fbq = function () {
        window.fbq.callMethod ? window.fbq.callMethod.apply(window.fbq, arguments) : window.fbq.queue.push(arguments);
      };

      if (!window._fbq) {
        window._fbq = window.fbq;
      }

      window.fbq.push = window.fbq;
      window.fbq.loaded = true;
      window.fbq.version = "2.0";
      window.fbq.queue = [];

      loadScript("https://connect.facebook.net/en_US/fbevents.js", () => {
        resolve();
      });
    } catch (error: unknown) {
      reject(error);
    }
  });
};

export { loadPixel };
