import isNil from "lodash/isNil";

import { ErrorInfoModel, ErrorModel, InterceptorModel } from "../../models";
import { ErrorType, InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";
import { isJsonRequest, parseResponseAsString } from "../../helpers";
import { typedJsonResponseError } from "../../errors";

import { isJsonString } from "~/utils";

import { LoggerService } from "~/services/logger";

const typedJsonResponse: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.JSON_FORMATTER,
  type: InterceptorType.RESPONSE,
  callback: async <T>({
    request,
    response,
    config: { model, allowEmptyResponse },
  }: InterceptorPayload<T>): Promise<InterceptorPayload<T>> => {
    if (isNil(response) || !response.ok || isNil(model) || !isJsonRequest(request)) {
      return { request, response };
    }

    if (allowEmptyResponse && !(await parseResponseAsString(response)).length) {
      return { request, response };
    }

    let shouldThrowTypeError: boolean;

    try {
      const responseJson: any = await response.clone().json();
      shouldThrowTypeError = isNil(responseJson) || typeof model !== typeof responseJson;
    } catch (e) {
      shouldThrowTypeError = true;
      LoggerService.error("typedJsonResponse", e);
    }

    if (shouldThrowTypeError) {
      const requestData: object = isJsonString(request?.body) ? JSON.parse(request.body) : {};
      const data: ErrorInfoModel = new ErrorInfoModel({ code: 1002, detail: "Invalid model or json is invalid" });
      throw typedJsonResponseError(new ErrorModel({ type: ErrorType.FRONTEND, data }), requestData);
    }

    return { request, response };
  },
});

export { typedJsonResponse };
