import pickBy from "lodash/pickBy";
import type { EventPayload } from "../types";

import type { QueryStorageService } from "~/services/QueryStorage/QueryStorage";

const getCampaignFromQueryStorage = (queryStorage: typeof QueryStorageService): EventPayload["context"]["campaign"] => {
  if (!queryStorage || !queryStorage?.visitModel) {
    return {};
  }

  return pickBy(
    {
      coupon: queryStorage.visitModel.data?.coupon,

      // click ids
      gclid: queryStorage.visitModel.data?.gclid,
      gbraid: queryStorage.visitModel.data?.gbraid,
      wbraid: queryStorage.visitModel.data?.wbraid,
      gclsrc: queryStorage.visitModel.data?.gclsrc,
      dclid: queryStorage.visitModel.data?.dclid,
      gad_source: queryStorage.visitModel.data?.gad_source,
      twclid: queryStorage.visitModel.data?.twclid,
      msclkid: queryStorage.visitModel.data?.msclkid,
      igshid: queryStorage.visitModel.data?.igshid,
      fbclid: queryStorage.visitModel.data?.fbclid,
      rdt_cid: queryStorage.visitModel.data?.rdt_cid,
      li_fat_id: queryStorage.visitModel.data?.li_fat_id,
      irclickid: queryStorage.visitModel.data?.irclickid,
      sccid: queryStorage.visitModel.data?.sccid,
      ttclid: queryStorage.visitModel.data?.ttclid,

      // utm
      content: queryStorage.visitModel.data?.utm_content,
      id: queryStorage.visitModel.data?.utm_id,
      medium: queryStorage.visitModel.data?.utm_medium,
      name: queryStorage.visitModel.data?.utm_campaign,
      source: queryStorage.visitModel.data?.utm_source,
      term: queryStorage.visitModel.data?.utm_term,
      test: queryStorage.visitModel.data?.utm_test,
    },
    (value: string = "") => value.length,
  );
};

export { getCampaignFromQueryStorage };
