// Source: https://developers.facebook.com/docs/meta-pixel/reference#standard-events

// Enum for Standard meta events
enum MetaEventName {
  ADD_PAYMENT_INFO = "AddPaymentInfo",
  ADD_TO_CART = "AddToCart",
  ADD_TO_WISHLIST = "AddToWishlist",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  CONTACT = "Contact",
  CUSTOMIZE_PRODUCT = "CustomizeProduct",
  DONATE = "Donate",
  FIND_LOCATION = "FindLocation",
  INITIATE_CHECKOUT = "InitiateCheckout",
  LEAD = "Lead",
  PURCHASE = "Purchase",
  SCHEDULE = "Schedule",
  SEARCH = "Search",
  START_TRIAL = "StartTrial",
  SUBMIT_APPLICATION = "SubmitApplication",
  SUBSCRIBE = "Subscribe",
  VIEW_CONTENT = "ViewContent",
}

export { MetaEventName };
