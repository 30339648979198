import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";

import type { CoreOptions } from "@segment/analytics-core";

import type { EventPayload } from "../../../types";

import { eventPayloadToUserData } from "./eventPayloadToUserData";
import { eventPayloadToPageData } from "./eventPayloadToPageData";

// https://segment.com/docs/connections/spec/common/
const eventPayloadToOptionsData = (eventPayload?: EventPayload | void): CoreOptions => {
  if (!eventPayload || isNil(eventPayload)) {
    return {} as CoreOptions;
  }

  const result: CoreOptions = {
    context: {
      page: eventPayloadToPageData(eventPayload),
      app: eventPayload?.context?.app,
      traits: eventPayloadToUserData(eventPayload),
      campaign: eventPayload?.context?.campaign as CoreOptions["context"]["campaign"],
      device: {
        id: eventPayload?.anonymousId,
        manufacturer: eventPayload?.context?.device?.vendor,
        model: eventPayload?.context?.device?.model,
        name: eventPayload?.context?.device?.os.name,
        version: eventPayload?.context?.device?.os.version,
      },
      locale: eventPayload?.context?.device?.language,
      userAgent: eventPayload?.context?.device?.userAgent,
      // userAgentData
      channel: eventPayload?.context?.channel,
    },
    messageId: eventPayload?.messageId,
  };

  return pickBy(result, (value: string | undefined) => value !== undefined);
};

export { eventPayloadToOptionsData };
