import { LoggerService } from "~/services/logger";

const isJsonString = (value: any): value is string => {
  try {
    JSON.parse(value);
  } catch (error) {
    LoggerService.warn("Not JSON:", error);
    return false;
  }

  return true;
};

export { isJsonString };
