import cloneDeep from "lodash/cloneDeep";
import parseAsString from "lodash/toString";

import { getGitVersion } from "~/modules/revision/helpers";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "~/services/fetch/types";
import { InterceptorModel } from "~/services/fetch/models";

const appInfoRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.APP_INFO,
  type: InterceptorType.REQUEST,
  callback: <T>({ request }: InterceptorPayload<T>): InterceptorPayload<T> => {
    const newRequest: RequestInit = cloneDeep(request);

    newRequest.headers = new Headers(newRequest?.headers);
    newRequest.headers.set("App-Build-Timestamp", parseAsString(Date.now()));
    newRequest.headers.set("App-Outdated", "false");
    newRequest.headers.set("Git-Commit-Head", getGitVersion());

    return { request: newRequest };
  },
});

export { appInfoRequest };
