import Cookies from "js-cookie";

const getSegmentAnonymousId = (): string | void => {
  const anonymousId: string | void = Cookies.get("ajs_anonymous_id");

  if (anonymousId && anonymousId?.length) {
    return anonymousId;
  }

  return null;
};

export { getSegmentAnonymousId };
