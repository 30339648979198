enum View {
  PASSWORDLESS_SIGN_IN = "passwordlessSignIn",
  PASSWORDLESS_SIGN_UP = "passwordlessSignUp",
  PASSWORD_RESET = "passwordReset",
  PASSWORD_RESET_CONFIRM = "passwordResetConfirm",
  SIGN_IN = "signIn",
  SIGN_UP = "signUp",
}

export { View };
