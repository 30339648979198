import cloneDeep from "lodash/cloneDeep";
import { UtmModel, VisitModel } from "./models";
import { StorageKey } from "./types";
import {
  createUtmModelFromQueryParameters,
  filterUniqueModels,
  filterUniqueModelsWithUtm,
  getPath,
  getReferer,
  isModelValid,
  isUtmModelEmpty,
  isVisitModelEmpty,
} from "./helpers";

import { StorageService } from "~/services/storage/Storage";
import { currentTimestamp } from "~/services/storage/helpers";

class QueryStorage {
  private static _SIZE_LIMIT: number = 35; // localStorage allows at best 52 k of lines
  private static _SIZE_LIMIT_SHORT: number = 5; // localStorage allows at best 52 k of lines

  public get hasVisitModels(): boolean {
    return !!this.visitModels.length;
  }

  public get visitModels(): VisitModel[] {
    return QueryStorage._getModels(StorageKey.VISIT_UTM).map((model: VisitModel) => new VisitModel(model));
  }

  public get visitModelsShort(): VisitModel[] {
    if (this.visitModels.length <= QueryStorage._SIZE_LIMIT_SHORT) {
      return this.visitModels;
    }

    const visitModels: VisitModel[] = cloneDeep(this.visitModels);
    const firstVisitModel: VisitModel = cloneDeep(this.visitModels[0]);

    return [firstVisitModel, ...visitModels.slice(-(visitModels.length - 4))];
  }

  /**
   * Returns last visit model with utm tags
   * @method
   */
  public get visitModel(): VisitModel | void {
    if (!this.visitModels.length) {
      return;
    }

    return cloneDeep(this.visitModels)
      .reverse()
      .find((visit: VisitModel) => !isUtmModelEmpty(visit?.data));
  }

  private static _getModels<T>(key: StorageKey): T[] {
    return StorageService.has(key) ? StorageService.get<T[]>(key) || [] : [];
  }

  private static _setVisitModels(visitModels: VisitModel[] = []): void {
    if (!visitModels || !visitModels.length) {
      return;
    }

    StorageService.set(StorageKey.VISIT_UTM, visitModels);
  }

  public cleanup(): void {
    if (!this.hasVisitModels) {
      return;
    }

    QueryStorage._setVisitModels(filterUniqueModelsWithUtm(this.visitModels));
  }

  public addVisitModel(): void {
    const visitModel: VisitModel = new VisitModel({
      createdAt: currentTimestamp(),
      path: getPath(),
    });

    const referer: string = getReferer();
    if (referer.length) {
      visitModel.referer = referer;
    }

    const utmTags: UtmModel | void = createUtmModelFromQueryParameters();
    if (utmTags) {
      visitModel.data = utmTags;
    }

    if (!isModelValid(visitModel) || isVisitModelEmpty(visitModel)) {
      return;
    }

    this._updateStorage(visitModel);
  }

  private _updateStorage(visitModel: VisitModel): void {
    let models: VisitModel[] = cloneDeep(this.visitModels);
    models.push(visitModel);
    models = filterUniqueModels(models);

    if (models.length >= QueryStorage._SIZE_LIMIT) {
      // It's important to preserve first client attribution, and last one
      delete models[1];
      models = models.filter(Boolean);
    }

    QueryStorage._setVisitModels(models);
  }
}

const QueryStorageService: QueryStorage = new QueryStorage();

export { QueryStorageService };
