import { LoggerService } from "~/services/logger";

// It seems that actually init is a track function that should be called on every page in our spa
const trackBrandzooka = (): void => {
  if (typeof window?.TTDUniversalPixelApi !== "function") {
    LoggerService.error("Brandzooka is not loaded.");
    return;
  }

  const { TTDUniversalPixelApi } = window;

  const universalPixelApi = new TTDUniversalPixelApi();
  universalPixelApi.init("2nkznre", ["v2aidk5"], "https://insight.adsrvr.org/track/up");
};

export { trackBrandzooka };
