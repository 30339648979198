const syncFunctionToAsyncFunction = <T>(fn: (...args: any[]) => T): ((...args: any[]) => Promise<T>) => {
  return (...args: any[]) => {
    return new Promise<T>((resolve, reject) => {
      try {
        const result = fn(...args);
        resolve(result);
      } catch (error: unknown) {
        console.error("asyncify error:", error);
        reject(error);
      }
    });
  };
};

export { syncFunctionToAsyncFunction };
