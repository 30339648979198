import { loadScript } from "~/utils/helpers/loadScript";

declare global {
  interface Window {
    UET: any;
    uetq: any;
  }
}

const loadPixel = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      window.uetq = window?.uetq || [];

      loadScript("//bat.bing.com/bat.js", () => {
        resolve();
      });
    } catch (error: unknown) {
      reject(error);
    }
  });
};

export { loadPixel };
