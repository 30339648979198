import merge from "lodash/merge";

import { VisitModel } from "~/services/QueryStorage/models";

class SaveUtmData {
  public utmDataArray: VisitModel[] = [];

  public constructor(source: Partial<SaveUtmData> = {}) {
    merge(this, source);
  }
}

export { SaveUtmData };
