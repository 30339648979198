import type { EventPayload } from "../../types";

const getDeviceNetworkContext = (): EventPayload["context"]["device"]["network"] => {
  const navigatorConnection: EventPayload["context"]["device"]["network"] | void =
    (
      navigator as unknown as {
        connection?: EventPayload["context"]["device"]["network"];
      }
    )?.connection || null;

  return {
    downlink: navigatorConnection?.downlink || -1,
    downlinkMax: navigatorConnection?.downlinkMax || -1,
    effectiveType: navigatorConnection?.effectiveType || "3g",
    rtt: navigatorConnection?.rtt || -1,
    saveData: navigatorConnection?.saveData || false,
    type: navigatorConnection?.type || "unknown",
  };
};

export { getDeviceNetworkContext };
