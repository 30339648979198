import merge from "lodash/merge";

import type { PromiseFunction } from "./types";

class Task<T, A extends any[]> {
  private readonly configuration!: {
    timeout: number;
    context: any;
  };

  private resolve!: (value: T) => void;
  private reject!: (error: unknown) => void;

  public constructor(
    private promiseFn: PromiseFunction<T, A>,
    private args: A = [] as A,
    configuration?: {
      timeout?: number;
      context?: any;
    },
  ) {
    this.configuration = merge({ timeout: 31000, context: null }, configuration);
  }

  public getPromise(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public executeTask(): Promise<void> {
    let isTaskCompleted = false;

    const timeoutPromise = new Promise<void>((_, reject) => {
      setTimeout(() => {
        if (!isTaskCompleted) {
          reject(new Error("Task timed out"));
        }
      }, this.configuration.timeout);
    });

    return Promise.race([this.promiseFn.apply(this.configuration.context, this.args), timeoutPromise])
      .then((result) => {
        isTaskCompleted = true;
        this.resolve(result as Awaited<T>);
      })
      .catch((error) => this.reject(error));
  }
}

export { Task };
