import { getModule } from "nuxt-property-decorator";
import { Repository } from "~/services/Repository";
import RevisionStore from "~/store/miscellaneous/revision";

export class RevisionRepository extends Repository<RevisionStore> {
  public get module(): RevisionStore {
    return getModule<RevisionStore>(RevisionStore, this.store);
  }
}

export const RevisionRepositoryService: RevisionRepository = new RevisionRepository();
