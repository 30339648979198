import parseAsNumber from "lodash/toNumber";
import parseAsString from "lodash/toString";

import { Module, VuexAction, VuexModule, VuexMutation } from "nuxt-property-decorator";

import { StorageService } from "~/services/storage/Storage";

@Module({
  name: "modules/auth",
  stateFactory: true,
  namespaced: true,
})
export default class AuthStore extends VuexModule {
  private static TWELVE_HOURS: number = 4.32e7;

  private _token: string = "";
  private _lastUpdated: ReturnType<Date["getTime"]> = 0;

  public get isTokenSet(): boolean {
    return !!this._token.length;
  }

  public get token(): string {
    return this._token;
  }

  public get lastUpdated(): AuthStore["_lastUpdated"] {
    return this._lastUpdated;
  }

  public get isOutdated(): boolean {
    if (!this.isTokenSet || !this._lastUpdated) {
      return false;
    }

    return this._lastUpdated + AuthStore.TWELVE_HOURS <= new Date().getTime();
  }

  @VuexMutation
  public setToken(token: string = ""): void {
    this._token = parseAsString(token);
  }

  @VuexMutation
  public setLastUpdated(lastUpdated: number = 0): void {
    this._lastUpdated = parseAsNumber(parseAsString(lastUpdated));
  }

  @VuexAction
  public updateAuthorization(token: string): void {
    this.setLastUpdated(new Date().getTime());
    this.setToken(token);

    StorageService.set("authorization", { token: this._token, lastUpdated: this._lastUpdated });
  }

  @VuexAction({ rawError: true })
  public onLogout(): void {
    this.reset();
  }

  @VuexMutation
  public reset(): void {
    this._token = "";
    this._lastUpdated = 0;

    StorageService.remove("authorization");
  }
}
