import isNil from "lodash/isNil";

const cleanObject = <T>(value: T): T => {
  const result: T = {} as T;

  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key) && !isNil(value?.[key])) {
      result[key] = value[key];
    }
  }

  return result;
};

export { cleanObject };
