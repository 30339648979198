import { VNode } from "vue";

/*
 * Idea from: https://vuejs.org/v2/guide/render-function.html#Complete-Example
 * Made in preparation for vue 3 and their ability to have multiple root nodes and not just one
 * */

const getChildrenTextContent: (defaultSlot: VNode | VNode[] | undefined) => string = (
  defaultSlot: VNode | VNode[] | undefined
): string => {
  if (!defaultSlot) {
    return "";
  }

  if (Array.isArray(defaultSlot)) {
    return defaultSlot
      .map((node) => {
        return node.children ? getChildrenTextContent(node.children) : node?.text || "";
      })
      .join("");
  }

  return defaultSlot?.text || "";
};

export { getChildrenTextContent };
