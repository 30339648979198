import merge from "lodash/merge";

import { Agreements } from "../../types/";
import { PatientModel } from "../../models";

// Note: backend has more fields in user model, but its redundant and patient fields are preferred
class UserModel {
  public id: string = "";
  public email: string = "";
  public createdAt: string = "";
  public firstName: string = "";
  public lastName: string = "";

  public patient: PatientModel | null = null;
  public referralCode: string = "";
  public agreements: Agreements[] = [];

  public constructor(user: Partial<UserModel> = {}) {
    merge(this, user);
  }
}

export { UserModel };
