import merge from "lodash/merge";

class AddressModel {
  public address: string = "";
  public city: string = "";
  public state: string = "";
  public zipcode: string = "";

  public constructor(source: Partial<AddressModel> = {}) {
    merge(this, source);
  }
}

export { AddressModel };
