/*
 * This password regex is from: https://www.w3resource.com/javascript/form/password-validation.php
 * Password between 8 to 40 characters which contain at least one numeric digit, one uppercase and one lowercase letter
 * */
import isNil from "lodash/isNil";
import isString from "lodash/isString";

const validatePassword = (password: string): boolean => {
  if (isNil(password) || !isString(password)) {
    return false;
  }

  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40}$/;
  return !!re?.test(password);
};

export { validatePassword };
