import type { EventPayload } from "../types";

import { cleanObject } from "~/utils/filters/cleanObject";

const getPageContext = (): EventPayload["page"] => {
  return cleanObject({
    path: window?.location?.pathname,
    referrer: document?.referrer,
    search: window?.location?.search,
    title: document?.title,
    url: window?.location?.href,
  });
};

export { getPageContext };
