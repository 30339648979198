import merge from "lodash/merge";
import { InterceptorCategory } from "../types";

class RequestConfigModel<Model = void> {
  public allowEmptyResponse: boolean = false; // we should always try and get correct response, but world is not ideal
  public controller: AbortController = new AbortController();
  public exclude: InterceptorCategory[] = [];
  public model: Model = null;
  public request: RequestInit = {}; // additional override for the request, could be used to override interceptor settings
  public timeout: number = 10; // in seconds

  public constructor(config: Partial<RequestConfigModel<Model>> = {}) {
    merge(this, config);
  }
}

export { RequestConfigModel };
