import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import isObjectLike from "lodash/isObjectLike";
import mergeWith from "lodash/mergeWith";

import { deepSeal } from "./deepSeal";

interface CloneDeepSafe {
  <TOrigin, TSource>(origin: TOrigin, source: TSource): TOrigin;

  <TOrigin, TSource1, TSource2>(origin: TOrigin, source1: TSource1, source2: TSource2): TOrigin;

  <TOrigin, TSource1, TSource2, TSource3>(
    origin: TOrigin,
    source1: TSource1,
    source2: TSource2,
    source3: TSource3
  ): TOrigin;

  <TOrigin, TSource1, TSource2, TSource3, TSource4>(
    origin: TOrigin,
    source1: TSource1,
    source2: TSource2,
    source3: TSource3,
    source4: TSource4
  ): TOrigin;

  (sourceOfTruth: any, ...otherSources: any[]): any;
}

const customizer = <T, J>(originValue: T, newValue: J): T | undefined => {
  if (isNil(originValue)) {
    return newValue as unknown as T;
  }

  if (isObjectLike(originValue) && isObjectLike(newValue) && isArray(originValue) && isArray(newValue)) {
    return undefined;
  }

  if (typeof originValue !== typeof newValue) {
    return originValue;
  }

  return undefined;
};

// todo: add clone deep for arrays with models as an example

// If safety is not important, use merge from lodash library, its 3x faster
const cloneDeepSafe: CloneDeepSafe = (origin: any, ...sourcesToMerge: any[]): any => {
  const originClone = cloneDeep(origin);
  deepSeal(originClone);
  return mergeWith(originClone, ...sourcesToMerge, customizer);
};

export { cloneDeepSafe };
