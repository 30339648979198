import { SentryScope, SentryTagKey } from "~/services/sentry/types";
import { SentryError } from "~/services/sentry/SentryError";

const templateError = (error: unknown | Error, fnName: string): SentryError =>
  new SentryError()
    .setError(error)
    .addFingerprint(`Utils.template::${fnName}`)
    .addTag(SentryTagKey.CALL, SentryScope.UTILS);

export { templateError };
