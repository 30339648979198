import get from "lodash/get";
import toString from "lodash/toString";

const templateReplacer = (match: string, data?: Record<string, any>): string => {
  const value: any = get(data, match, "");

  if (value) {
    return toString(value);
  }

  return "";
};

export { templateReplacer };
