import merge from "lodash/merge";

import { Gender, RelationToUser } from "../../types";
import { AddressModel } from "./AddressModel";

class PatientModel {
  public currentAddress: AddressModel = new AddressModel();
  public dateOfBirth: string | null = "";
  public firstName: string = "";
  public genderEnum: Gender = Gender.DECLINED;
  public guardianFirstName: string = "";
  public guardianLastName: string = "";
  public id: string = "";
  public lastName: string = "";
  public middleName: string = "";
  public phone: string | "not provided" = "";
  public progressStage: string = "";
  public relationshipToUserEnum: RelationToUser = RelationToUser.SELF;
  public identityConfirmedAt: string | null = null; // Backend datetime (looks like timestamp / 1000) in UTC time, or null if not verified
  public relationToUser: number;

  public constructor(patient: Partial<PatientModel> = {}) {
    merge(this, patient);
  }
}

export { PatientModel };
