import { appInfoRequest, authorizationRequest } from "./interceptors";

import { Fetch, FetchService } from "~/services/fetch/Fetch";
import { InterceptorModel } from "~/services/fetch/models";
import { InterceptorCategory } from "~/services/fetch/types";

const fetchService: Fetch = FetchService;

fetchService.requestInterceptors = fetchService.requestInterceptors.map((interceptor: InterceptorModel) => {
  if (interceptor.category === InterceptorCategory.AUTHORIZATION) {
    return authorizationRequest;
  }

  if (interceptor.category === InterceptorCategory.APP_INFO) {
    return appInfoRequest;
  }

  return interceptor;
});

export { fetchService };
