import { isScriptLoaded, loadScript } from "~/utils";
import { LoggerService } from "~/services/logger";

declare global {
  interface Window {
    clarity: any;
  }
}

const loadClarity = (pixelId: string = "coobf2g4ga"): void => {
  if (!pixelId?.length) {
    LoggerService.error("Clarity pixel id is missing.", pixelId);
    return;
  }

  window.clarity =
    window.clarity ||
    function () {
      (window.clarity.q = window.clarity.q || []).push(arguments);
    };

  const scriptUrl: string = `https://www.clarity.ms/tag/${pixelId}`;
  if (isScriptLoaded(scriptUrl)) {
    LoggerService.warn("Clarity script is already loaded.", scriptUrl);
    return;
  }

  loadScript(scriptUrl);
};

export { loadClarity };
