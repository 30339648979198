import { htmlNewLineToUnicodeNewLine } from "~/utils/converters/htmlNewLineToUnicodeNewLine";
import { stripHtml } from "~/utils/converters/stripHtml";

const createEmailHref = ({ recipient = "", subject = "", body = "" }) => {
  const email: Record<string, string> = {
    title: encodeURIComponent(recipient),
    subject: stripHtml(htmlNewLineToUnicodeNewLine(subject)),
    body: stripHtml(htmlNewLineToUnicodeNewLine(body)),
  };

  return `mailto:${email.title}?subject=${email.subject}&body=${email.body}`;
};

export { createEmailHref };
