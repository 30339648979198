import isNil from "lodash/isNil";
import parseAsString from "lodash/toString";
import parseAsNumber from "lodash/toNumber";

import type { EventPayload } from "../../../types";

import type { ClaritasParameters } from "../types/claritasParameters";

import { cleanObject } from "~/utils/filters/cleanObject";

import { AnalyticsEventNames } from "~/mixins/demographics/types/analyticsEventNames";

const eventPayloadToClaritasTrackEvent = (
  pixelId: string,
  event: "Page View" | string,
  eventPayload?: EventPayload | void,
): ClaritasParameters => {
  if (!eventPayload || isNil(eventPayload)) {
    return;
  }

  const commonProperties: Pick<ClaritasParameters, "ppt" | "cid" | "idfa" | "ord"> = {
    ppt: parseAsNumber(pixelId),
    cid: undefined, // todo: find it
    idfa: eventPayload?.anonymousId,
    ord: parseAsString(parseInt(parseAsString(new Date().getTime() + Math.random() * 10000000000000), 10)),
  };

  const eventProperties: Pick<ClaritasParameters, "gid" | "g"> = {} as Pick<ClaritasParameters, "gid" | "g">;
  switch (event) {
    case "Page View": {
      eventProperties.gid = 59837;
      eventProperties.g = "site_visit";
      break;
    }

    case AnalyticsEventNames.CHECKOUT_USER_SIGNED_UP: {
      eventProperties.gid = 59838;
      eventProperties.g = "lead";
      break;
    }

    case AnalyticsEventNames.CHECKOUT_SESSION_COMPLETED: {
      eventProperties.gid = 59839;
      eventProperties.g = "sign_up";
      break;
    }
  }

  return cleanObject({
    ...commonProperties,
    ...eventProperties,
  });
};

export { eventPayloadToClaritasTrackEvent };
