import merge from "lodash/merge";

import { TemplateUrlOptions } from "./types";
import { templateUrlOptionsDefault } from "./defaults";
import { normalizeUrl } from "./normalizeUrl";
import { template } from "./template";
import { templateError } from "./errors";

import { SentryService } from "~/services/sentry/SentryService";

export const templateUrl = (
  url: string,
  data?: Record<string, any>,
  templateUrlOptions: TemplateUrlOptions = templateUrlOptionsDefault
): string => {
  try {
    const options: TemplateUrlOptions = merge(templateUrlOptionsDefault, templateUrlOptions);

    if (options.normalize) {
      return normalizeUrl(template(url, data));
    }

    return template(url, data);
  } catch (error) {
    if (!templateUrlOptions.skipSentryError) {
      SentryService.report(
        templateError(error, "templateUrl")
          .addExtra("url", url)
          .addExtra("data", data)
          .addExtra("templateUrlOptions", templateUrlOptions as any)
      );
    }
  }
};
