import { QueryStorageService } from "../QueryStorage";
import { ApiUserService } from "~/services/ApiUser";

const updateBackendUtmInformation = async (): Promise<void> => {
  if (QueryStorageService.hasVisitModels) {
    try {
      await ApiUserService.saveUtm(QueryStorageService.visitModels);
      QueryStorageService.cleanup();
    } catch (e) {
      console.error("Save utm error:", e);
    }
  }
};

export { updateBackendUtmInformation };
