/*
 * This email regex is from: https://emailregex.com/
 * */
import isNil from "lodash/isNil";
import isString from "lodash/isString";

const validateEmail = (email: string): boolean => {
  if (isNil(email) || !isString(email)) {
    return false;
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!re?.test(email?.toLowerCase());
};

export { validateEmail };
