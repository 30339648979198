enum Url {
  AUTO_LOGIN = "auth/auto-login",
  PASSWORDLESS_SIGN_IN = "auth/passwordless-login",
  PASSWORD_RESET = "auth/reset-password",
  PASSWORD_RESET_CONFIRM = "auth/confirm-reset-password",
  REFRESH_TOKEN = "auth/refresh-token",
  SIGN_IN = "auth/obtain-token",
  SIGN_UP = "accounts/user",
  APPLE_SIGN_IN = "auth/apple-id-token",
  GOOGLE_SIGN_IN = "auth/google-id-token",
}

export { Url };
