import isFunction from "lodash/isFunction";
import { SentryContextModel, SentryUserModel } from "./models";
import { SentryError } from "./SentryError";
import { SentryEventsQueueService } from "./SentryEventsQueue";
import { ScopeContext, SentryInstance, Severity } from "./types";
import { capitalizeFirstCharacter } from "~/utils";
import { Task } from "~/services/taskDeprecated/Task";
import { LoggerService } from "~/services/logger";

class Sentry {
  public static TTL: number = 10000;

  private _user: SentryUserModel = new SentryUserModel();
  private _sentry: SentryInstance;
  private _level: Severity = Severity.Error;

  public get user(): SentryUserModel {
    return this._user;
  }

  public get sentry(): SentryInstance {
    return this._sentry;
  }

  public get isLoaded(): boolean {
    return isFunction(this._sentry?.captureException);
  }

  public get level(): Severity {
    return this._level;
  }

  private static _printMessage(error: unknown | Error, context: ScopeContext, level: Severity): void {
    const contextMessage: string = `${capitalizeFirstCharacter(level)} context: `;

    switch (level) {
      case Severity.Debug:
      case Severity.Info:
        LoggerService.info("Info: ", error);
        LoggerService.info(contextMessage, context);
        break;
      case Severity.Warning:
        LoggerService.warn("Warning: ", error);
        LoggerService.warn(contextMessage, context);
        break;
      case Severity.Critical:
      case Severity.Error:
      case Severity.Fatal:
        LoggerService.error("Error: ", error);
        LoggerService.error(contextMessage, context);
        break;
      default:
        LoggerService.log("Log: ", error);
        LoggerService.log(contextMessage, context);
    }
  }

  public init(sentry: SentryInstance): void {
    this._sentry = sentry;
    this._executeSentryCall();
  }

  public reset(): void {
    const resetTask: Task = new Task(() => {
      this._level = Severity.Error;
    })
      .setContext(SentryService)
      .setTtl(Sentry.TTL);

    this._executeSentryCall(resetTask);
  }

  public setUser(id: string = null, username: string = null, email: string = null, ipAddress: string = null): void {
    // This will not report user if error occurred before calling this method, like page crash
    const user: SentryUserModel = new SentryUserModel(id, username, email, ipAddress);

    const setSentryUserTask: Task = new Task(() => {
      this.sentry.setUser(user);
      this._user = user;
    })
      .setContext(SentryService)
      .setTtl(Sentry.TTL);

    this._executeSentryCall(setSentryUserTask);
  }

  public clearUser(): void {
    const clearSentryUserTask: Task = new Task(() => {
      this.sentry.setUser(null);
      this._user = new SentryUserModel();
    })
      .setContext(SentryService)
      .setTtl(Sentry.TTL);

    this._executeSentryCall(clearSentryUserTask);
  }

  public setSeverity(level: Severity): Sentry {
    const setSeverityTask: Task = new Task(() => {
      this._level = level;
    })
      .setContext(SentryService)
      .setTtl(Sentry.TTL);

    this._executeSentryCall(setSeverityTask);

    return this;
  }

  public report(sentryError: SentryError, disableDebug: boolean = false): Sentry {
    const context: ScopeContext = new SentryContextModel(
      this.user,
      this.level,
      sentryError.extras,
      sentryError.contexts,
      sentryError.tags,
      sentryError.fingerprints,
      sentryError.requestSession,
    );

    const reportTask: Task = new Task(() => {
      this.sentry.captureException(sentryError, context);
      if (!disableDebug) {
        Sentry._printMessage(sentryError, context, this.level);
      }
    })
      .setContext(SentryService)
      .setTtl(Sentry.TTL);

    SentryEventsQueueService.addTask(reportTask);
    this.reset();

    return this;
  }

  private _executeSentryCall(task?: Task): void {
    task && SentryEventsQueueService.addTask(task);

    if (this.isLoaded) {
      SentryEventsQueueService.executeTasks();
      return;
    }

    LoggerService.warn("Sentry is not loaded, task added to a queue.");
  }
}

const SentryService: Sentry = new Sentry();

export { SentryService, Sentry };
