import { StorageStrategy } from "../abstracts/StorageStrategy";

class LocalStorageStrategy extends StorageStrategy {
  public get<U>(key: string, format: "v1" | "v2" = "v2"): U {
    const data: string = window?.localStorage?.getItem?.(key);
    if (format === "v1") {
      return this._formatDataOnGetWithOldEncoding(data);
    }

    return this.formatDataOnGet<U>(data);
  }

  public has(key: string): boolean {
    return key in localStorage;
  }

  public remove(key: string): LocalStorageStrategy {
    window?.localStorage?.removeItem?.(key);

    return this;
  }

  public set<U>(key: string, data: U): LocalStorageStrategy {
    window?.localStorage?.setItem?.(key, this.formatDataOnSet<U>(data));

    return this;
  }
}

export { LocalStorageStrategy };
