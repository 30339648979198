import isNil from "lodash/isNil";
import isFinite from "lodash/isFinite";

const isDateInRange = (date: number, min: number = 0, max: number): boolean => {
  if (isNil(date) || !isFinite(date)) {
    return false;
  }

  return date >= min && date <= max + min;
};

export { isDateInRange };
