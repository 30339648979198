import isNil from "lodash/isNil";

import type { Properties } from "posthog-js";

import mapKeys from "lodash/mapKeys";
import camelCase from "lodash/camelCase";

import type { EventPayload } from "../../../types";

import { cleanObject } from "~/utils/filters/cleanObject";

/*
 * It's better to have all event properties in PostHog flattered, otherwise, we will have to use HogQL to work with them
 *  */

// https://posthog.com/docs
// https://posthog.com/docs/product-analytics/autocapture#autocaptured-properties
const eventPayloadToPosthogCapturePropertiesData = (eventPayload?: EventPayload | void): Properties => {
  if (!eventPayload || isNil(eventPayload)) {
    return {} as Properties;
  }

  return cleanObject({
    // prefix custom event names, as it can conflict with standard PostHog events
    ...mapKeys(eventPayload?.properties || {}, (_value, key: string) => camelCase(`extra ${key}`)),

    $device_id: eventPayload?.anonymousId,
    $anon_distinct_id: eventPayload?.anonymousId,
    distinct_id: eventPayload?.userId,
    $is_identified: !!eventPayload?.userId?.length,
    $timestamp: eventPayload?.sentAt,

    $raw_user_agent: eventPayload?.context?.device?.userAgent,

    utm_source: eventPayload?.context?.campaign?.source,
    utm_medium: eventPayload?.context?.campaign?.medium,
    utm_campaign: eventPayload?.context?.campaign?.name,
    utm_content: eventPayload?.context?.campaign?.content,
    utm_term: eventPayload?.context?.campaign?.term,

    // page properties
    $current_url: eventPayload?.page?.url,
    $pathname: eventPayload?.page?.path,
    $referrer: eventPayload?.page?.referrer,
    //

    // known google analytics properties
    // $entry_gclid: eventPayload?.context?.campaign?.gclid,
    // $entry_gad_source: eventPayload?.context?.campaign?.gad_source,
    //

    $gclid: eventPayload?.context?.campaign?.gclid,
    $gad_source: eventPayload?.context?.campaign?.gad_source,
    $gclsrc: eventPayload?.context?.campaign?.gclsrc,
    $dclid: eventPayload?.context?.campaign?.dclid,
    $wbraid: eventPayload?.context?.campaign?.wbraid,
    $gbraid: eventPayload?.context?.campaign?.gbraid,
    $fbclid: eventPayload?.context?.campaign?.fbclid,
    $msclkid: eventPayload?.context?.campaign?.msclkid,
    $twclid: eventPayload?.context?.campaign?.twclid,
    $li_fat_id: eventPayload?.context?.campaign?.li_fat_id, // docs mentions la_fat_id which is incorrect
    $igshid: eventPayload?.context?.campaign?.igshid,
    $ttclid: eventPayload?.context?.campaign?.ttclid,

    $screen_height: eventPayload?.context?.device?.screen?.height,
    $screen_width: eventPayload?.context?.device?.screen?.width,
    $viewport_height: eventPayload?.context?.device?.screen?.viewport?.height,
    $viewport_width: eventPayload?.context?.device?.screen?.viewport?.width,

    $lib: eventPayload?.context?.app?.name,
    $lib_version: eventPayload?.context?.app?.version,

    $app_name: eventPayload?.context?.app?.name,
    $app_version: eventPayload?.context?.app?.version,
  });
};

export { eventPayloadToPosthogCapturePropertiesData };
