import { RenderContext } from "vue";

/*
 * We need to get this manually because functional components dont support this by default
 * Link: https://github.com/vuejs/vue/blame/8f6f1d355c6d6b0916e7669dddad4e326eade367/test/unit/features/options/_scopeId.spec.js#L92
 * */

const getVDataAttribute: (renderContext: RenderContext) => string = (renderContext: RenderContext): string => {
  if (
    typeof (renderContext?.parent?.$options as any)?._scopeId === "string" &&
    (renderContext.parent.$options as any)._scopeId.length
  ) {
    return (renderContext.parent.$options as any)._scopeId;
  }

  return "";
};

export { getVDataAttribute };
