import { format, fromUnixTime } from "date-fns";

import parseAsString from "lodash/toString";
import parseAsNumber from "lodash/toNumber";

const unixTimeToLongDate = (date: string | number, _format: string = "MMMM d, yyyy"): string => {
  const formattedDate: number = parseAsNumber(parseAsString(date));

  try {
    return format(fromUnixTime(formattedDate), _format);
  } catch (e) {
    console.error("unixTimeToLongDate:", e);
    return "";
  }
};

export { unixTimeToLongDate };
