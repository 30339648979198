import isFinite from "lodash/isFinite";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";

import { VisitModel } from "../models";

import { isObject } from "~/utils";

const isModelValid = (model?: VisitModel | void): model is VisitModel => {
  return isObject(model) && isString(model?.path) && isNumber(model?.createdAt) && isFinite(model?.createdAt);
};

export { isModelValid };
