import Cookies from "js-cookie";

const isCookiesStorageSupported = (): boolean => {
  const testKey = "__storage_test__" as const;
  const testValue = "__storage_value-test__" as const;

  try {
    Cookies.set(testKey, testValue, { expires: 365 * 100 });
    const isCookiesWorking: boolean = Cookies.get(testKey) === testValue;
    Cookies.remove(testKey);

    return isCookiesWorking;
  } catch (error) {
    return false;
  }
};

export { isCookiesStorageSupported };
