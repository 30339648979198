import { StorageStrategy } from "../abstracts/StorageStrategy";

class SessionStorageStrategy extends StorageStrategy {
  public get<U>(key: string, format: "v1" | "v2" = "v2"): U {
    const data: string = window?.sessionStorage?.getItem?.(key);
    if (format === "v1") {
      return this._formatDataOnGetWithOldEncoding(data);
    }

    return this.formatDataOnGet<U>(data);
  }

  public has(key: string): boolean {
    return key in sessionStorage;
  }

  public remove(key: string): SessionStorageStrategy {
    window?.sessionStorage?.removeItem?.(key);

    return this;
  }

  public set<U>(key: string, data: U): SessionStorageStrategy {
    window?.sessionStorage?.setItem?.(key, this.formatDataOnSet<U>(data));

    return this;
  }
}

export { SessionStorageStrategy };
