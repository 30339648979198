import isObject from "lodash/isObject";
import isString from "lodash/isString";

import { UtmModel } from "../models";

const isUtmModelEmpty = (model: UtmModel): model is UtmModel => {
  if (!model || !isObject(model)) {
    return true;
  }

  return !Object.values(model)
    .filter(isString)
    .filter((value: string) => !!value.length).length;
};

export { isUtmModelEmpty };
