import cloneDeep from "lodash/cloneDeep";

import { InterceptorCategory, InterceptorCallback, InterceptorType } from "../types";

class InterceptorModel {
  public callback: InterceptorCallback;
  public category: InterceptorCategory;
  public type: InterceptorType;

  public constructor(interceptor: InterceptorModel) {
    Object.assign(this, cloneDeep(interceptor));
  }
}

export { InterceptorModel };
