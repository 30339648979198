import { LoggerService } from "~/services/logger";

const parseResponseAsString = async (response: Response): Promise<string> => {
  try {
    return await response.clone().text();
  } catch (e) {
    LoggerService.error("parseResponseAsString", e);
  }

  return "";
};

export { parseResponseAsString };
