const stringToStyles = (_styles: string): Partial<CSSStyleDeclaration> => {
  const styles = {} as CSSStyleDeclaration;
  _styles.split(";").forEach((s) => {
    const parts = s.split(":", 2);
    if (parts.length > 1) {
      // @ts-ignore
      styles[parts[0].trim().replace(/-([a-z])/gi, (_, l) => l.toUpperCase())] = parts[1].trim();
    }
  });
  return styles;
};

export { stringToStyles };
