import type { EventPayload } from "../../../types";
import type { GoogleTagUserData } from "../types";

import { removeAllButNumbers } from "~/utils/converters/removeAllButNumbers";

// it looks like there is inconsistencies when properties are named for the backend container:
// https://developers.google.com/analytics/devguides/collection/ga4/uid-data ??
// https://support.google.com/analytics/answer/14171598?hl=en
const eventPayloadToGoogleTagUserData = (eventPayload: EventPayload): GoogleTagUserData => {
  const result: GoogleTagUserData = {};

  const email: string | void = eventPayload?.traits?.email?.toLowerCase()?.trim() || undefined;
  if (email?.length) {
    result.email = email;
  }

  const phoneNumber: string | void = removeAllButNumbers(eventPayload?.traits?.phone)?.trim() || undefined;
  if (phoneNumber?.length) {
    result.phone_number = `+${phoneNumber}`;
  }

  const firstName: string | void = eventPayload?.traits?.firstName?.replace(/\s/g, "") || undefined;
  if (firstName?.length) {
    result.address = {};
    result.address.first_name = firstName;
  }

  const lastName: string | void = eventPayload?.traits?.lastName?.replace(/\s/g, "") || undefined;
  if (lastName?.length) {
    result.address = { ...(result.address || {}) };
    result.address.last_name = lastName;
  }

  const street: string | void = eventPayload?.traits?.address?.street?.replace(/\s/g, "") || undefined;
  if (street?.length) {
    result.address = { ...(result.address || {}) };
    result.address.street = street;
  }
  // Property -> client -> standard -> address - phone
  // user_data.address.first_name
  const city: string | void = eventPayload?.traits?.address?.city?.replace(/\s/g, "") || undefined;
  if (city?.length) {
    result.address = { ...(result.address || {}) };
    result.address.city = city;
  }

  const state: string | void = eventPayload?.traits?.address?.state?.trim() || undefined;
  if (state?.length) {
    result.address = { ...(result.address || {}) };
    result.address.region = state;
  }

  const postalCode: string | void = eventPayload?.traits?.address?.postalCode?.trim()?.substring(0, 5) || undefined;
  if (postalCode?.length) {
    result.address = { ...(result.address || {}) };
    result.address.postal_code = postalCode;
  }

  const country: "US" | void = state?.length ? "US" : undefined;
  if (country?.length) {
    result.address = { ...(result.address || {}) };
    result.address.country = country;
  }

  return result;
};

export { eventPayloadToGoogleTagUserData };
