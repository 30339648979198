import { VNodeData } from "vue";

const mergeVDataWithContextData: (vNodeData: VNodeData, vDataAttribute: string) => VNodeData = (
  vNodeData: VNodeData,
  vDataAttribute: string
): VNodeData => {
  if (!vNodeData || !vDataAttribute.length) {
    return vNodeData;
  }

  return {
    ...vNodeData,
    attrs: {
      ...(vNodeData?.attrs || {}),
      [vDataAttribute]: "",
    },
  };
};

export { mergeVDataWithContextData };
