import parseAsString from "lodash/toString";

import { isLocalStorageSupported } from "~/utils/tests/isLocalStorageSupported";

const getFromLocalStorage = (keys: string[]): Record<string, string> => {
  const result: Record<string, string> = {};
  keys.forEach((key) => {
    result[key] = parseAsString((isLocalStorageSupported() && localStorage.getItem(key)) || "");
  });

  return result;
};

export { getFromLocalStorage };
