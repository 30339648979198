import { VNodeData } from "vue";

const mergeVDataWithAttributes: (attributes: VNodeData["attrs"], vDataAttribute: string) => VNodeData["attrs"] = (
  attributes: VNodeData["attrs"],
  vDataAttribute: string
): VNodeData["attrs"] => {
  const newAttributes: VNodeData["attrs"] = attributes || {};
  if (vDataAttribute.length) {
    newAttributes[vDataAttribute] = "";
  }

  return newAttributes;
};

export { mergeVDataWithAttributes };
